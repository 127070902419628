import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Checkbox from 'components/button/Checkbox'
import Button from 'components/button/Button'

import useReducer from 'store/useReducer'
import * as SitesStore from 'site/store'
import * as UserStore from 'store/user/user'

import useI18n from 'i18n/useI18n'

interface Props {
  filtersSelected: string[]
  data: string[]
  onFiltersSelected: (opt: string[]) => void
  typeFilter: 'equipments' | 'buildings'
}

const RoomFilter = ({ data, onFiltersSelected, filtersSelected, typeFilter }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()
  const user = useReducer(UserStore.store, (s) => s.user)
  const site = useReducer(SitesStore.store, (s) => s.site)

  const [optionsSelected, setOptionsSelected] = React.useState<string[]>(filtersSelected)

  const findItem = (item: string): boolean => !!optionsSelected.find((option) => option === item)

  const renderItemFilter = ({ item }: { item: string }) => (
    <CheckboxContainer>
      <Checkbox
        label={item}
        active={findItem(item)}
        onClick={() => {
          setOptionsSelected(
            findItem(item) ? optionsSelected.filter((option) => option !== item) : [...optionsSelected, item]
          )
        }}
        ariaLabel="filter"
        textColor={theme.colors.primaryText}
      />
    </CheckboxContainer>
  )

  if (!site || !user) {
    return null
  }

  return (
    <DrawerContainer>
      <HeaderContainer>
        <Title>
          {i18n.t(`screens.meeting.booking.picker.label.${typeFilter === 'buildings' ? 'buildings' : 'equipments'}`)}
        </Title>
      </HeaderContainer>
      {data.length === 0 ? (
        <NoDataContainer>
          <NoData>
            {i18n.t(
              `screens.meeting.booking.picker.label.${typeFilter === 'buildings' ? 'noBuildings' : 'noEquipments'}`
            )}
          </NoData>
        </NoDataContainer>
      ) : (
        <OptionsContainer>{data.map((item) => renderItemFilter({ item }))}</OptionsContainer>
      )}
      <ButtonContainer>
        <Button
          label={i18n.t('screens.meeting.booking.picker.label.apply')}
          onClick={() => onFiltersSelected(optionsSelected)}
        />
      </ButtonContainer>
    </DrawerContainer>
  )
}

export default RoomFilter

const Title = styled('p')`
  ${(props) => props.theme.fonts.h3Bold};
`

const DrawerContainer = styled('div')`
  height: 378px;
  padding: 0 24px;
`

const HeaderContainer = styled('div')`
  flex-direction: row;
  margin-bottom: 22px;
  justify-content: space-between;
  align-items: center;
`

const NoDataContainer = styled('div')`
  flex: 1;
  justify-content: center;
  align-items: center;
`

const NoData = styled('p')`
  ${(props) => props.theme.fonts.body};
`

const OptionsContainer = styled('div')`
  height: 100%;
  overflow-y: auto;
  margin: 0 10px 20px;
`

const CheckboxContainer = styled('div')`
  margin: 10px 0;
`

const ButtonContainer = styled('div')`
  align-items: center;
`
