import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import { overwriteHtmlFonts } from 'theme/Theme'

import Main from 'components/main/Main'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Tree from 'components/button/Tree'
import Loader from 'components/status/Loader'
import Alert from 'components/alert/Alert'
import FormSection from 'components/form/FormSection'
import FormField from 'components/form/FormField'
import ToggleField from 'components/form/ToggleField'
import PickerPortal from 'components/picker/PickerModal'
import PickerItem from 'components/picker/PickerItem'
import Button from 'components/button/Button'
import Checkbox from 'components/button/Checkbox'
import DatePickerModal from 'components/picker/DatePickerModal'

import AnimationDetailContent from './AnimationDetailContent'
import RegisterContactMail from './RegisterContactMail'
import RegisterBookingModals from './RegisterBookingModals'

import useI18n from 'i18n/useI18n'

import useNavigation from 'core/src/layout/useNavigation'

import api from './api'
import siteApi from 'site/api'
import directoryApi from 'directory365/api'

import { DirectoryUser } from 'directory365/types'

import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'
import * as UserStore from 'store/user/user'
import * as RegisterStore from './store'

import {
  CONTENT_WIDTH,
  FAMILY_TOGGLE_TYPES,
  MAX_CHILDREN,
  formValidationSchema,
  getBirthDateFromString,
  isBirthDateValid,
} from './utils'
import Logger from 'utils/Logger'
import { breakpoints } from 'utils/breakpoints'
import { format, isBefore } from 'date-fns'

import { useFormik, FormikErrors } from 'formik'

interface Props {
  id: string
  slotId: string
  bookingId?: string
  emailData?: AnimationEmailUser
}

const INITIAL_DELEGATED_PERSON: DelegatedPerson = { firstname: '', lastname: '', phone: '', childLink: '' }

const INITIAL_CHILD: ChildBaseForm = {
  childFirstname: '',
  childLastname: '',
  childBirthDate: '',
  additionalInformation: undefined,
}

const INITIAL_VALUES: FamilyPostForm = {
  parentFirstname: '',
  parentLastname: '',
  parentPhone: '',
  division: undefined,
  delegatedPerson: undefined,
  isGroupSupervisor: false,
  lunchWithChildren: false,
  bookingId: '',
  children: [INITIAL_CHILD],
  additionalQuestions: undefined,
}

const RegisterFamilyScreen = ({ id, slotId, bookingId }: Props) => {
  const i18n = useI18n()
  const navigation = useNavigation()
  const [theme] = useTheme()

  const site = useReducer(SiteStore.store, (s) => s.site)
  const user = useReducer(UserStore.store, (s) => s.user)
  const animationUser = useReducer(RegisterStore.store, (s) => s.animationUser)
  const delegatedEmailData = useReducer(RegisterStore.store, (s) => s.delegatedEmailUser)

  const [status, setStatus] = React.useState<ScreenStatus>('loading')
  const [detail, setDetail] = React.useState<AnimationDetail>()
  const [reservation, setReservation] = React.useState<Booking>()
  const [divisions, setDivisions] = React.useState<LorealDivision[]>([])
  const [isDelegatedPerson, setIsDelegatedPerson] = React.useState(false)
  const [areTermsAccepted, setAreTermsAccepted] = React.useState(false)
  const [emailUserData, setEmailUserData] = React.useState<DirectoryUser>()

  // On évite de valider tout le formulaire dès le début, sinon tous les champs sont en erreur
  const [validateOnChange, setValidateOnChange] = React.useState(false)

  const slot = React.useMemo(() => detail?.slots.find((s) => s.id === slotId), [detail, slotId])

  const onSubmit = (values: FamilyPostForm) => {
    if (!!detail && !!slot) {
      // Affichage des pop-ups de droits à l'image puis de confirmation
      RegisterBookingModals.open({
        navigation,
        i18n,
        detail,
        slot,
        reservation,
        familyForm: values,
        siteId: site?.id,
      })
    }
  }

  const { values, setValues, setFieldValue, handleChange, handleSubmit, errors, isValid, isSubmitting } = useFormik<
    FamilyPostForm
  >({
    initialValues: INITIAL_VALUES,
    onSubmit,
    validationSchema: formValidationSchema(i18n, divisions, isDelegatedPerson, slot, detail),
    validateOnChange,
    isInitialValid: false,
  })

  const divisionValue = React.useMemo(
    () => (!!values.division ? i18n.t(`screens.meeting.catering.form.divisions.${values.division}`) : undefined),
    [values.division]
  )

  const defaultParentValues = React.useMemo(() => {
    const userToDisplay = !delegatedEmailData ? user : emailUserData
    return {
      parentFirstname: userToDisplay?.givenName || '',
      parentLastname: userToDisplay?.surname || '',
      parentPhone: userToDisplay?.mobilePhone || '',
      division: (!delegatedEmailData ? animationUser : delegatedEmailData.data)?.division,
    }
  }, [!!user, !!emailUserData, !!delegatedEmailData, animationUser])

  const additionalQuestions: AdditionalQuestionPostForm[] | undefined = React.useMemo(
    () =>
      detail?.informationForm?.additionalQuestions?.map((q) => ({
        id: q.id,
        value: reservation?.questionAnswers?.find((a) => a.id === q.id)?.value || (q.type === 'BOOLEAN' ? 'false' : ''),
      })),
    [!!detail?.informationForm, !!reservation?.questionAnswers]
  )

  React.useEffect(() => {
    // On réinitialise l'email du collaborateur (en cas d'inscription déléguée) au changement de page
    return () => RegisterStore.actions.resetDelegatedEmailUser()
  }, [])

  React.useEffect(() => {
    if (!!delegatedEmailData) {
      directoryApi
        .searchByEmail(delegatedEmailData.email)
        .then(({ value }) => setEmailUserData(value.pop()))
        .catch(Logger.error)
    }
  }, [!!delegatedEmailData])

  React.useEffect(() => {
    setStatus('loading')
    // Récupération des infos de l'animation
    api
      .getDetail(id)
      .then((detail) => {
        if (
          (detail.status !== 'CREATED' && detail.status !== 'FINISHED') ||
          isBefore(new Date(), new Date(detail.publicationDate))
        ) {
          // La page du formulaire ne devrait pas être accessible
          setStatus('error')
          navigation.push('/register')
          Alert.open({
            title: i18n.t('common.error'),
            description: i18n.t(`screens.register.detail.statusError.${detail.status || 'CANCELLED'}`),
          })
        } else if (!detail.informationForm || detail.animationType !== 'FAMILY_DAY') {
          setStatus('error')
        } else {
          setAreTermsAccepted(!detail.termsAndConditions)
          setDetail(detail)
          setStatus('ok')
        }
      })
      .catch((err) => {
        Logger.error(err)
        setStatus('error')
      })
  }, [id])

  React.useEffect(() => {
    if (!!bookingId) {
      // Récupération des infos de la réservation
      api
        .getReservations(false)
        .then(({ bookings }) => {
          const booking = bookings.find((r) => r.id === bookingId)
          if (
            !booking ||
            !['BOOKED', 'SHARED', 'FAMILY'].includes(booking.status) ||
            isBefore(new Date(booking?.startDate), new Date())
          ) {
            setStatus('error')
          } else {
            setReservation(booking)
            setStatus('ok')
          }
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }, [bookingId])

  React.useEffect(() => {
    if (!!detail && detail.informationForm?.needDivisions) {
      // Récupération de la liste des divisions si nécessaire
      siteApi
        .getDivisions()
        .then(({ divisions }) => setDivisions(divisions || []))
        .catch(Logger.error)
    }
  }, [!!detail])

  React.useEffect(() => {
    if (!!detail) {
      const defaultValues = { ...INITIAL_VALUES, ...defaultParentValues, additionalQuestions }
      if (!!reservation?.familyInformationForm) {
        // On remplit le formulaire avec les données de la réservation à modifier
        const {
          parentFirstname,
          parentLastname,
          parentPhone,
          division,
          isGroupSupervisor,
          lunchWithChildren,
          delegatedPerson,
          children,
        } = reservation.familyInformationForm
        setValues({
          ...defaultValues,
          parentFirstname: parentFirstname || defaultValues.parentFirstname,
          parentLastname: parentLastname || defaultValues.parentLastname,
          parentPhone: parentPhone || defaultValues.parentPhone,
          isGroupSupervisor: !!isGroupSupervisor,
          lunchWithChildren: !!lunchWithChildren,
          division: division || defaultValues.division,
          delegatedPerson: !!delegatedPerson && delegatedPerson?.lastname !== null ? delegatedPerson : undefined,
          bookingId: reservation.id,
          children:
            children.length > 0
              ? children.map((c) => ({
                  ...c,
                  additionalInformation: c.additionalInformation !== null ? c.additionalInformation : undefined,
                }))
              : [INITIAL_CHILD],
        })
        setIsDelegatedPerson(!!delegatedPerson && delegatedPerson?.lastname !== null)
      } else {
        setValues(defaultValues)
      }
    }
  }, [!!detail, !!reservation, defaultParentValues, additionalQuestions])

  React.useEffect(() => {
    // Conditions acceptées par défaut si on modifie une réservation existante
    setAreTermsAccepted((!!detail && !detail.termsAndConditions) || !!reservation)
  }, [!!detail, !!reservation])

  React.useEffect(() => {
    if (validateOnChange && !isValid && isSubmitting) {
      // Erreur(s) dans le formulaire au clic sur Valider
      document.getElementById('screenContainer')?.scrollTo({ top: 0, behavior: 'smooth' })
      Alert.open({
        title: i18n.t('common.warning'),
        description: i18n.t('screens.register.detail.familyForm.invalidForm'),
      })
    }
  }, [validateOnChange, isValid, isSubmitting])

  const openDivisionPicker = () =>
    PickerPortal.open({
      title: i18n.t('screens.meeting.catering.form.fieldPlaceholder.division'),
      selected: values.division,
      data: divisions.map((div) => ({
        label: div,
        value: div,
        customRender: (selected) => (
          <PickerItem
            key={div}
            selected={selected}
            label={i18n.t(`screens.meeting.catering.form.divisions.${div}`)}
            color={theme.colors.functionalities.register}
          />
        ),
      })),
      onItemSelected: (item) => setFieldValue('division', item),
      buttonColor: theme.colors.primaryDark,
      withSearch: true,
    })

  const openBirthDatePicker = (inputId: string, childBirthDate: string) => {
    DatePickerModal.open({
      date: getBirthDateFromString(childBirthDate),
      onDateSelected: (date) => setFieldValue(inputId, format(date, 'dd-MM-yyyy')),
      title: i18n.t('screens.register.detail.familyForm.labels.childBirthDate', {
        startDate: detail?.informationForm?.childStartBirthdateAllowed,
        endDate: detail?.informationForm?.childEndBirthdateAllowed,
      }),
      dateValid: (date) => !date || (!!slot && !!detail && isBirthDateValid(slot, detail, format(date, 'dd-MM-yyyy'))),
      displayErrorText: i18n.t('screens.register.detail.familyForm.invalidBirthDate', {
        startDate: detail?.informationForm?.childStartBirthdateAllowed,
        endDate: detail?.informationForm?.childEndBirthdateAllowed,
      }),
    })
  }

  const renderInputField = (
    field: keyof Omit<FamilyPostForm, 'isGroupSupervisor' | 'lunchWithChildren' | 'additionalQuestions'>,
    labelKey?: string,
    placeholderKey?: string,
    delegatedSubfield?: keyof DelegatedPerson,
    childSubfield?: keyof ChildBaseForm,
    childIndex?: number
  ) => {
    // Informations de la personne déléguée (si toggle activé)
    if (field === 'delegatedPerson') {
      if (!delegatedSubfield || !values.delegatedPerson) {
        return null
      }
      const inputId = `delegatedPerson.${delegatedSubfield}`
      return (
        <FormField
          type="string"
          fieldType="smallInput"
          error={
            !!errors.delegatedPerson &&
            typeof errors.delegatedPerson === 'object' &&
            Object.keys(errors.delegatedPerson).includes(delegatedSubfield)
              ? errors.delegatedPerson[delegatedSubfield]
              : undefined
          }
          inputId={inputId as keyof FamilyPostForm}
          title={i18n.t(`screens.register.detail.familyForm.labels.${delegatedSubfield}`)}
          placeholder={i18n.t(`screens.register.detail.familyForm.placeholders.${delegatedSubfield}`)}
          value={values.delegatedPerson[delegatedSubfield]}
          onChange={handleChange}
        />
      )
    }
    // Informations d'un enfant de la liste
    if (field === 'children') {
      if (!childSubfield || childIndex === undefined || childIndex >= values.children.length) {
        return null
      }
      const inputId = `children.${childIndex}.${childSubfield}`
      const childErrors = (!!errors.children &&
      typeof errors.children === 'object' &&
      errors.children.length > childIndex
        ? errors.children[childIndex]
        : undefined) as FormikErrors<ChildBaseForm> | undefined
      const isChildBirthDate = childSubfield === 'childBirthDate'
      return (
        <FormField
          type="string"
          fieldType={isChildBirthDate ? 'button' : 'smallInput'}
          error={
            !!childErrors && Object.keys(childErrors).includes(childSubfield) ? childErrors[childSubfield] : undefined
          }
          inputId={inputId as keyof FamilyPostForm}
          title={i18n.t(`screens.register.detail.familyForm.labels.${labelKey || childSubfield}`, {
            startDate: detail?.informationForm?.childStartBirthdateAllowed,
            endDate: detail?.informationForm?.childEndBirthdateAllowed,
          })}
          placeholder={i18n.t(`screens.register.detail.familyForm.placeholders.${placeholderKey || childSubfield}`)}
          value={values.children[childIndex][childSubfield]}
          onChange={(event) => !isChildBirthDate && handleChange(event)}
          onClick={() => isChildBirthDate && openBirthDatePicker(inputId, values.children[childIndex].childBirthDate)}
        />
      )
    }
    // Informations du parent
    return (
      <FormField
        type="string"
        fieldType="smallInput"
        error={Object.keys(errors).includes(field) ? errors[field] : undefined}
        inputId={field}
        title={i18n.t(`screens.register.detail.familyForm.labels.${labelKey || field}`)}
        placeholder={i18n.t(`screens.register.detail.familyForm.placeholders.${placeholderKey || field}`)}
        value={values[field]}
        onChange={handleChange}
      />
    )
  }

  const renderToggleField = (field: FamilyToggleType) => {
    if (!!detail?.informationForm) {
      const { canBeGroupSupervisor, canBeDelegated, canLunchOnSite } = detail.informationForm
      if (
        (field === 'isGroupSupervisor' && canBeGroupSupervisor) ||
        (field === 'delegatedPerson' && canBeDelegated) ||
        (field === 'lunchWithChildren' && canLunchOnSite)
      ) {
        const isSeparator =
          (field === 'isGroupSupervisor' && canBeDelegated) || (field === 'lunchWithChildren' && canBeGroupSupervisor)
        const onClick = () => {
          if (field === 'delegatedPerson') {
            // Réinitialisation des infos de la personne déléguée au clic sur le toggle
            setFieldValue('delegatedPerson', isDelegatedPerson ? undefined : INITIAL_DELEGATED_PERSON)
            setIsDelegatedPerson(!isDelegatedPerson)
          } else {
            setFieldValue(field, !values[field])
          }
        }
        return (
          <ToggleField
            id={field}
            active={field === 'delegatedPerson' ? isDelegatedPerson : values[field]}
            label={i18n.t(`screens.register.detail.familyForm.toggle.${field}`)}
            onClick={onClick}
            isSeparator={isSeparator}
            renderContent={
              field === 'delegatedPerson' && isDelegatedPerson
                ? () => (
                    <>
                      <Line>
                        {renderInputField('delegatedPerson', undefined, undefined, 'lastname')}
                        {renderInputField('delegatedPerson', undefined, undefined, 'firstname')}
                      </Line>
                      {renderInputField('delegatedPerson', undefined, undefined, 'phone')}
                      {renderInputField('delegatedPerson', undefined, undefined, 'childLink')}
                    </>
                  )
                : undefined
            }
          />
        )
      }
    }
  }

  const renderAdditionalQuestion = (question: AdditionalQuestionForm, index: number) => {
    const value = !!values.additionalQuestions ? values.additionalQuestions[index].value : ''
    if (question.type === 'BOOLEAN') {
      const isTrue = value === 'true'
      return (
        <ToggleField
          id={question.id}
          active={isTrue}
          htmlLabel={question.questionTitle}
          onClick={() => setFieldValue(`additionalQuestions.${index}.value`, isTrue ? 'false' : 'true')}
          isSeparator
        />
      )
    }
    const inputId = `additionalQuestions.${index}.value` as keyof FamilyPostForm
    const error =
      !!errors.additionalQuestions && index < errors.additionalQuestions.length
        ? errors.additionalQuestions[index]
        : undefined
    return (
      <>
        <Separator />
        <FormField
          type="string"
          fieldType="smallInput"
          error={error}
          inputId={inputId}
          htmlTitle={question.questionTitle}
          value={value}
          onChange={handleChange}
        />
      </>
    )
  }

  const renderChild = (_: ChildBaseForm, index: number) => (
    <ChildContainer key={`child-${index}`}>
      {index > 0 && <Separator />}
      <ChildHeader>
        <ChildTitle>{i18n.t('screens.register.detail.familyForm.childNumber', { count: index + 1 })}</ChildTitle>
        {values.children.length > 1 && (
          <SmallButtonContainer>
            <Button
              label={i18n.t('common.delete')}
              onClick={() =>
                setFieldValue(
                  'children',
                  values.children.filter((_, i) => i !== index)
                )
              }
              width={100}
              borderTextColor={theme.colors.delete}
              style="small"
            />
          </SmallButtonContainer>
        )}
      </ChildHeader>
      <Line>
        {renderInputField('children', 'lastname', 'lastname', undefined, 'childLastname', index)}
        {renderInputField('children', 'firstname', 'firstname', undefined, 'childFirstname', index)}
      </Line>
      {renderInputField('children', undefined, undefined, undefined, 'childBirthDate', index)}
      {renderInputField(
        'children',
        'additionalInformation',
        'additionalInformation',
        undefined,
        'additionalInformation',
        index
      )}
    </ChildContainer>
  )

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={i18n.t('screens.register.detail.title')} />

        <Tree
          previousPages={[
            { url: 'register', title: 'screens.register.title' },
            { url: `register/${id}`, title: detail?.name || '...' },
          ]}
          currentPageTitle={i18n.t('screens.register.detail.familyForm.breadcrumb')}
        />

        <ContentContainer id="familyFormContainer">
          {status === 'loading' ? (
            <Loader />
          ) : status === 'error' || !detail || !slot ? (
            <ErrorContainer>
              <Error>{i18n.t('common.errorDescription')}</Error>
            </ErrorContainer>
          ) : (
            <Form onSubmit={(e) => e.preventDefault()}>
              <AnimationDetailContent detail={detail} slot={slot} small />

              <FormContent>
                <FormSection title={i18n.t('screens.register.detail.familyForm.sections.parents')} gap={16}>
                  <>
                    <Line>
                      {renderInputField('parentLastname', 'lastname')}
                      {renderInputField('parentFirstname', 'firstname')}
                    </Line>

                    {renderInputField('parentPhone', 'phone')}

                    {divisions.length > 0 && (
                      <FormField
                        fieldType="picker"
                        inputId="division"
                        error={errors.division}
                        onClick={openDivisionPicker}
                        title={i18n.t('screens.register.detail.familyForm.labels.division')}
                        value={divisionValue}
                        placeholder={i18n.t('screens.register.detail.familyForm.placeholders.division')}
                      />
                    )}

                    <TogglesContainer>
                      {FAMILY_TOGGLE_TYPES.map(renderToggleField)}
                      {detail.informationForm?.additionalQuestions?.map(renderAdditionalQuestion)}
                    </TogglesContainer>
                  </>
                </FormSection>

                <FormSection
                  title={i18n.t('screens.register.detail.familyForm.sections.children')}
                  subtitle={i18n.t('screens.register.detail.familyForm.sections.childrenMax', { max: MAX_CHILDREN })}
                  gap={16}>
                  <>
                    {values.children.map(renderChild)}
                    {values.children.length < MAX_CHILDREN && (
                      <SmallButtonContainer marginTop={16}>
                        <Button
                          label={i18n.t('screens.register.detail.familyForm.addChild')}
                          onClick={() => setFieldValue('children', [...values.children, INITIAL_CHILD])}
                          width={150}
                          style="small"
                        />
                      </SmallButtonContainer>
                    )}
                  </>
                </FormSection>

                {!!detail.termsAndConditions && (
                  <FormSection
                    title={i18n.t('screens.register.detail.familyForm.sections.third', {
                      title: detail.termsAndConditions.title,
                    })}
                    gap={16}>
                    <ConditionsText dangerouslySetInnerHTML={{ __html: detail.termsAndConditions.description }} />
                    <ConditionsLine>
                      <CheckboxContainer>
                        <Checkbox
                          ariaLabel={i18n.t(
                            `accessibility.ariaLabels.register.checkBoxTerms${areTermsAccepted ? '' : 'Not'}Checked`
                          )}
                          active={areTermsAccepted}
                          onClick={() => setAreTermsAccepted(!areTermsAccepted)}
                        />
                      </CheckboxContainer>
                      <ConditionsText dangerouslySetInnerHTML={{ __html: detail.termsAndConditions.acceptText }} />
                    </ConditionsLine>
                  </FormSection>
                )}
              </FormContent>

              <ButtonsContainer>
                <Button
                  label={i18n.t('common.validate')}
                  onClick={() => {
                    handleSubmit()
                    setValidateOnChange(true)
                  }}
                  disabled={!isValid || !areTermsAccepted}
                />
                <Button
                  label={i18n.t('common.cancel')}
                  onClick={() => navigation.push(`/register/${detail.id}${!!reservation ? '/' + reservation.id : ''}`)}
                  style="secondary"
                />
              </ButtonsContainer>

              <RegisterContactMail contactMail={detail.contactMail} />
            </Form>
          )}
        </ContentContainer>
      </ScreenContainer>
    </Main>
  )
}

export default RegisterFamilyScreen

// SCREEN CONTENT

const ScreenContainer = styled.div`
  padding: 50px 75px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
  }
`

const ContentContainer = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: center;
  max-width: ${CONTENT_WIDTH}px;
`

const ErrorContainer = styled.div`
  flex: 1;
  padding: 24px;
  align-items: center;
  justify-content: center;
`

const Text = styled('p')`
  margin: 0;
  padding: 0;
`

const Error = styled(Text)`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryText};
  text-align: center;
`

// FORM STRUCTURE

const Form = styled.form``

const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
  gap: 16px;
  width: 100%;
  max-width: ${CONTENT_WIDTH}px;
`

const Line = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    flex-direction: column;
  }
`

const TogglesContainer = styled.div`
  gap: 16px;
`

const Separator = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.secondaryLightGrey};
  margin: 0;
`

// CHILD FIELDS

const ChildContainer = styled.div`
  gap: 16px;
`

const ChildHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ChildTitle = styled(Text)`
  ${(props) => props.theme.fonts.subtitleBold};
`

// TERMS AND CONDITIONS

const ConditionsLine = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: start;
`

const CheckboxContainer = styled.div`
  margin-top: 3px;
`

const ConditionsText = styled(Text)`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.primaryText};
  line-height: 24px;
  ${overwriteHtmlFonts};
`

// BUTTONS

const SmallButtonContainer = styled.div<{ marginTop?: number }>`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  margin-top: ${(props) => props.marginTop || 0}px;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
  gap: 16px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    flex-direction: column;
    align-self: center;
  }
`
