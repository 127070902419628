import { createEvent, createStore } from 'effector'

export const actions = {
  setShortcuts: createEvent<string[]>('setShortcuts'),
  setTemporaryDeeplink: createEvent<string | undefined>('setTemporaryDeeplink'),
  reset: createEvent('reset'),
}

const initialState: HomeState = {
  shortcuts: ['restaurant', 'booking', 'phonebook'],
  temporaryDeeplink: undefined,
}

export const store = createStore<HomeState>(initialState, { name: 'home' })
  .on(actions.setShortcuts, (s, shortcuts) => ({ ...s, shortcuts }))
  .on(actions.setTemporaryDeeplink, (s, deeplink) => ({ ...s, temporaryDeeplink: deeplink }))
  .reset(actions.reset)
