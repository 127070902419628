import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

import { capitalize } from 'utils/stringUtils'
import { isSameDay } from 'date-fns'

interface Props {
  days: string[]
  selectedDay?: string
  setSelectedDay: (day: string) => void
  maxWidth?: number
}

const DaySelectorList = ({ days, selectedDay, setSelectedDay, maxWidth }: Props) => {
  const i18n = useI18n()

  const currentIndex = React.useMemo(
    () => (!selectedDay ? 0 : days.findIndex((d) => isSameDay(new Date(d), new Date(selectedDay)))),
    [days, selectedDay]
  )

  const onKeyDownFunction = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowLeft' && currentIndex > 0) {
      setSelectedDay(days[currentIndex - 1])
    } else if (e.key === 'ArrowRight' && currentIndex < days.length - 1) {
      setSelectedDay(days[currentIndex + 1])
    }
  }

  const renderDay = (item: string) => {
    const date = new Date(item)

    return (
      <li key={item.toLocaleString()}>
        <DayContainer selected={item === selectedDay} onClick={() => setSelectedDay(item)}>
          <DayNumber>{i18n.t('screens.register.detail.dayNumber', { date })}</DayNumber>
          <DayName>{capitalize(i18n.t('screens.register.detail.monthString', { date }).slice(0, 3) + '.')}</DayName>
          <DayName>{capitalize(i18n.t('screens.register.detail.dayString', { date }).slice(0, 3) + '.')}</DayName>
        </DayContainer>
      </li>
    )
  }

  return (
    <DaysList maxWidth={maxWidth} tabIndex={0} onKeyDown={(e) => onKeyDownFunction(e)}>
      {days.map(renderDay)}
    </DaysList>
  )
}

export default DaySelectorList

const Text = styled('p')`
  margin: 0;
  padding: 0;
`

const DaysList = styled('ul')<{ maxWidth?: number }>`
  list-style: none;
  display: flex;
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px`};
  gap: 12px;
  margin: 0;
  padding: 0 16px 16px 0;
  mask-image: linear-gradient(to right, white 98%, transparent);

  // Scrollbar non visible mais scroll autorisé :
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-overflow-style: none; // Internet Explorer, Edge
  scrollbar-width: 0px; // Firefox
  ::-webkit-scrollbar {
    display: none; // Chrome, Safari, Opera
  }
`

const DayContainer = styled('button')<{ selected: boolean }>`
  flex-direction: column;
  width: 62px;
  height: 62px;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid ${(props) => (props.selected ? props.theme.colors.primaryDark : props.theme.colors.lightGrey)};
  cursor: pointer;
  background-color: ${(props) => (props.selected ? props.theme.colors.contentBackground : props.theme.colors.white)};
  transition: all 0.25s ease-in-out;
  outline: none;
  :hover {
    box-shadow: 0px 2px 3px 0px rgba(146, 146, 146, 0.25);
  }
`

const DayNumber = styled(Text)`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.primaryText};
`

const DayName = styled(Text)`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryText};
`
