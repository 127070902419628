import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Category from 'components/label/Category'
import IconLabel from 'components/label/IconLabel'
import Loader from 'components/status/Loader'
import Tree from 'components/button/Tree'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main from 'components/main/Main'
import Planning from 'components/planning/Planning'
import EditBookingModal from 'components/modal/EditBookingModal'

import useI18n from 'i18n/useI18n'

import useNavigation from 'core/src/layout/useNavigation'

import { breakpoints } from 'utils/breakpoints'
import { displayName } from 'visioglobe/utils'
import { getDay } from 'register/utils'
import { bookRoom } from './utils'
import { addDays, addMinutes, isWeekend, roundToNearestMinutes } from 'date-fns'

import api from './api'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  id: string // room email
}

const MAX_WIDTH = 600

const DAYS_OF_PLANNING = 28 // 4 semaines

const RoomDetailScreen = ({ id }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()
  const navigation = useNavigation()

  const [schedule, setSchedule] = React.useState<ScheduleData>()
  const [loading, setLoading] = React.useState(false)
  const [selectedDay, setSelectedDay] = React.useState<string>(getDay(new Date().toISOString()))

  const fetch = () => {
    setLoading(true)
    api
      .schedule(id)
      .then(setSchedule)
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    analytics.screen({
      screen_feature: values.screens.news,
      screen_name: values.screens.newsDetail,
      screen_object_id: id,
    })

    fetch()
  }, [])

  const libelle = !!schedule?.referentiel ? displayName(schedule.referentiel, i18n.lang) : id
  const data = schedule?.referentiel?.data
  const description = data && data[`description_${i18n.lang}`]
  const equipments = data && data[`equipements_${i18n.lang}`]

  const daysOfPlanning = Array.from(Array(DAYS_OF_PLANNING), (_, index) => addDays(new Date(), index))
    .filter((d) => !isWeekend(d))
    .map((d) => getDay(d.toISOString()))

  const openBookingModal = () => {
    if (!!schedule?.referentiel) {
      EditBookingModal.open({
        title: i18n.t('screens.meeting.booking.alert.book'),
        buttonLabel: i18n.t('common.book'),
        referentiel: schedule.referentiel,
      })
    }
  }

  const bookNow = () => {
    const room = schedule?.referentiel
    if (!!room) {
      const roundStartDate = roundToNearestMinutes(new Date(), { nearestTo: 5 })
      const roundEndDate = addMinutes(roundStartDate, 45)

      bookRoom(
        i18n,
        room,
        roundStartDate,
        roundEndDate,
        () =>
          analytics.event({
            event_feature: values.eventName.booking,
            event_action: values.actions.validateBooking,
            event_object_id: room.reference,
          }),
        setLoading
      )
    }
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={`${i18n.t('screens.meeting.booking.roomDetail.titleDetailPage')}${libelle}`} />

        <Tree previousPages={[{ url: 'meeting', title: 'screens.meeting.booking.title' }]} currentPageTitle={libelle} />

        {loading ? (
          <Loader />
        ) : !schedule || !schedule.referentiel ? (
          <Loader />
        ) : (
          <ContentContainer>
            <Title>{libelle}</Title>

            {!!schedule.referentiel.image && (
              <ImageContainer>
                <ItemImage src={schedule.referentiel.image} />
              </ImageContainer>
            )}

            <ActionsContainer>
              {!!schedule.referentiel.room && (
                <IconLabel
                  icon="map"
                  label={i18n.t('screens.meeting.booking.actions.localize')}
                  onClick={() => navigation.push(`/map?location=${schedule.referentiel!.room}`)}
                  focusable
                />
              )}
              {!!schedule.referentiel.bookable && (
                <IconLabel
                  icon="agenda"
                  label={i18n.t('screens.meeting.booking.book')}
                  onClick={openBookingModal}
                  focusable
                />
              )}
              {!!schedule.referentiel.bookable && (
                <IconLabel
                  icon="book"
                  label={i18n.t('screens.meeting.booking.actions.bookNow')}
                  onClick={bookNow}
                  focusable
                />
              )}
            </ActionsContainer>

            <SectionTitle role="header">{i18n.t('screens.meeting.booking.roomDetail.planning')}</SectionTitle>

            {!!schedule.meetings && (
              <Planning
                meetings={schedule.meetings.map((m) => ({ start: m.startDateTime, end: m.endDateTime }))}
                day={selectedDay}
                setDay={setSelectedDay}
                daysList={daysOfPlanning}
              />
            )}

            <SectionTitle role="header">{i18n.t('screens.meeting.booking.roomDetail.infos')}</SectionTitle>

            <InformationContainer>
              {!!description && (
                <Category icon="text" iconColor={theme.colors.iconicGrey} iconSize={18} label={description} />
              )}

              {!!schedule.referentiel.capacity && (
                <Category
                  icon="user"
                  iconColor={theme.colors.iconicGrey}
                  iconSize={18}
                  label={i18n.t(`components.capacity.value`, { count: schedule.referentiel.capacity })}
                />
              )}

              {!!equipments && (
                <Category
                  icon="info"
                  iconColor={theme.colors.iconicGrey}
                  iconSize={18}
                  label={equipments.replace(' -', ',')}
                />
              )}

              <Category
                icon="building"
                iconColor={theme.colors.iconicGrey}
                iconSize={18}
                label={i18n.t('screens.meeting.booking.roomDetail.location', {
                  site: schedule.referentiel.site,
                  building: schedule.referentiel.building,
                  floor: schedule.referentiel.floor,
                })}
              />
            </InformationContainer>
          </ContentContainer>
        )}
      </ScreenContainer>
    </Main>
  )
}

export default RoomDetailScreen

const ScreenContainer = styled('div')`
  display: flex;
  padding: 44px 80px;

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 44px 55px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 44px 35px;
  }
`

const ContentContainer = styled('div')`
  padding: 40px 200px;
  display: flex;
  flex: 1;
  width: 645px;
  justify-content: center;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 20px 0px;
    width: 100%;
  }
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h1Bold};
  word-break: break-word;
  margin: 0;
`

const ImageContainer = styled('div')`
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 297px;
  width: 400px;
  margin-top: 24px;
  border-radius: 12px;
  background-color: ${(props) => props.theme.colors.functionalities.practicalinformation}0D;

  @media only screen and (max-width: ${breakpoints.small}px) {
    height: 297px;
    width: 100%;
  }
`

const ItemImage = styled('img')`
  object-fit: cover;
  user-select: none;
  aspect-ratio: 4/3;
  height: 297px;
  width: 400px;
  border-radius: 12px;

  @media only screen and (max-width: ${breakpoints.small}px) {
    height: 297px;
    width: 100%;
  }
`

const ActionsContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  max-width: ${MAX_WIDTH - 32}px;
  gap: 0px;
  margin: 24px 0 16px;
  padding: 16px;
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.contentBackground};

  @media only screen and (max-width: ${breakpoints.small}px) {
    flex-direction: column;
    gap: 16px;
    align-self: flex-start;
    align-items: flex-start;
  }
`

const SectionTitle = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 20px 0;
  word-break: break-word;
`

const InformationContainer = styled('div')`
  gap: 8px;
`
