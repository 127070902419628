import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icon'
import Alert from 'components/alert/Alert'
import ModalLoader from 'components/status/ModalLoader'
import EditBookingModal from 'components/modal/EditBookingModal'

import { Link } from 'react-router-dom'

import useI18n from 'i18n/useI18n'

import useReducer from 'store/useReducer'
import * as BookingStore from './store'

import api from './api'

import { startOfDay } from 'date-fns'
import { displayName } from 'visioglobe/utils'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

type MeetingAndReferentiel = {
  meeting: Meeting
  referentiel: ReferentielData
}
type MeetingsByDate = {
  date: Date
  data: MeetingAndReferentiel[]
}

const MyBookingList = () => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const reservations = useReducer(BookingStore.store, (s) => s.reservations || [])

  const meetingsByDate = React.useMemo(
    () =>
      reservations.reduce((acc, cur) => {
        cur.meetings?.forEach((m) => {
          const date = startOfDay(new Date(m.startDateTime))
          let ms = acc.find((a) => a.date === date)
          if (!ms) {
            ms = {
              date,
              data: [],
            }
            acc.push(ms)
          }
          ms.data.push({
            meeting: m,
            referentiel: cur.referentiel!,
          })
        })
        return acc
      }, [] as MeetingsByDate[]),
    [reservations]
  )

  const loadBookings = () => {
    api.getReservations().then((res) => BookingStore.actions.setReservations(res.schedules || []))
  }

  React.useEffect(() => {
    loadBookings()
  }, [])

  const deleteMeeting = (meeting: MeetingAndReferentiel) => {
    if (!meeting.meeting.id) {
      return Promise.reject()
    }
    return api.deleteReservation(meeting.meeting.id, meeting.referentiel.email!)
  }

  const onDelete = (meeting: MeetingAndReferentiel) => {
    Alert.open({
      title: i18n.t('screens.meeting.booking.alert.deleteBooking'),
      description: i18n.t('screens.meeting.booking.alert.deleteBookingMessage', {
        room: displayName(meeting.referentiel, i18n.lang),
        start: new Date(meeting.meeting.startDateTime),
        end: new Date(meeting.meeting.endDateTime),
      }),
      buttons: [
        {
          label: i18n.t('common.yes'),
          onClick: () => {
            ModalLoader.open()

            analytics.event({
              event_feature: values.eventName.booking,
              event_action: values.actions.deleteBooking,
              event_object_id: meeting.referentiel.reference,
            })

            deleteMeeting(meeting)
              .then(() =>
                Alert.open({
                  title: i18n.t('screens.meeting.booking.alert.deleteSuccess'),
                  description: '',
                })
              )
              .catch(() =>
                Alert.open({
                  title: i18n.t('common.error'),
                  description: i18n.t('screens.meeting.booking.alert.errorMessage'),
                })
              )
              .finally(() => {
                ModalLoader.close()
                loadBookings()
              })
          },
        },
        { label: i18n.t('common.no'), onClick: Alert.close },
      ],
    })
  }

  const onUpdate = (meeting: MeetingAndReferentiel) =>
    EditBookingModal.open({
      title: i18n.t('screens.meeting.booking.alert.updateBooking'),
      buttonLabel: i18n.t('common.modify'),
      meetingId: meeting.meeting.id,
      referentiel: meeting.referentiel,
    })

  const mapEvent = (referentiel: ReferentielData) =>
    analytics.event({
      event_feature: values.eventName.booking,
      event_action: values.actions.redirectMap,
      event_object_id: referentiel.reference,
    })

  const renderMeeting = (resa: MeetingAndReferentiel, index: number, list: MeetingAndReferentiel[]) => {
    const locationInfo = resa.referentiel
    const name = displayName(locationInfo, i18n.lang)
    const meeting = resa.meeting
    return (
      <React.Fragment key={meeting.id}>
        <MeetingContainer>
          <MeetingHeader>
            <Location>{name}</Location>
            <Timespan>
              {i18n.t('screens.meeting.booking.timespan', {
                start: new Date(`${meeting.startDateTime}`),
                end: new Date(`${meeting.endDateTime}`),
              })}
            </Timespan>
          </MeetingHeader>
          {(!!locationInfo.referencialType ||
            !!locationInfo.site ||
            !!locationInfo.building ||
            !!locationInfo.floor) && (
            <Infos>
              {locationInfo.referencialType && <Info>{locationInfo.referencialType}</Info>}
              <Info>
                {locationInfo.site && locationInfo.site}
                {locationInfo.building && ', ' + locationInfo.building}
                {locationInfo.floor && ', ' + i18n.t('screens.meeting.booking.floor', { floor: locationInfo.floor })}
              </Info>
            </Infos>
          )}
          <Buttons>
            {locationInfo.room && (
              <ItemLink to={`/map?location=${locationInfo.room}`} onClick={() => mapEvent(locationInfo)}>
                <Icon name="map" size={20} color={Theme.colors.secondary} />
                <Label>{i18n.t('screens.meeting.booking.actions.localize')}</Label>
              </ItemLink>
            )}
            <ItemButton onClick={() => onUpdate(resa)}>
              <Icon name="pencil" size={20} color={Theme.colors.secondary} cursor="pointer" />
              <Label>{i18n.t('screens.meeting.booking.actions.edit')}</Label>
            </ItemButton>
            <ItemButton onClick={() => onDelete(resa)}>
              <Icon name="trash" size={20} color={Theme.colors.secondary} cursor="pointer" />
              <Label>{i18n.t('screens.meeting.booking.actions.delete')}</Label>
            </ItemButton>
          </Buttons>
        </MeetingContainer>
        {index !== list.length - 1 && <Separator />}
      </React.Fragment>
    )
  }

  const renderMeetingsByDate = (meetings: MeetingsByDate) => (
    <DateContainer key={meetings.date.getTime()}>
      <DateValue>{i18n.t('screens.meeting.booking.picker.value.start', { date: meetings.date })}</DateValue>
      {meetings.data.map(renderMeeting)}
    </DateContainer>
  )

  return (
    <Container>
      <Title>{i18n.t('screens.meeting.booking.myBookings')}</Title>
      {meetingsByDate.length > 0 ? (
        meetingsByDate.map(renderMeetingsByDate)
      ) : (
        <NoMeeting>{i18n.t('screens.meeting.booking.noBooking')}</NoMeeting>
      )}
    </Container>
  )
}

export default MyBookingList

const Container = styled('div')`
  overflow-x: hidden;
  overflow-y: auto;
`

const Title = styled('h1')`
  padding: 0px 22px 10px 22px;
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px;
`

const DateContainer = styled('div')``

const DateValue = styled('h2')`
  ${(props) => props.theme.fonts.bodyBold};
  padding: 6px 24px;
  border: 0px;
  border-bottom-width: 1px;
  border-top-width: 1px;
  color: ${(props) => props.theme.colors.primary};
  margin: 0px;
`

const MeetingContainer = styled('div')`
  padding: 20px 22px;
`

const MeetingHeader = styled('div')`
  ${(props) => props.theme.fonts.subtitleBold};
  flex-direction: row;
`

const Separator = styled('div')`
  height: 1px;
  margin: 0px 22px;
  background-color: ${(props) => props.theme.colors.lightGrey};
`

const Location = styled('p')`
  flex: 1;
  margin: 0px 20px 0px 0px;
  ${(props) => props.theme.fonts.subtitleBold};
  font-family: 'Georgia';
`

const Timespan = styled('p')`
  ${(props) => props.theme.fonts.subtitleBold};
  font-family: 'Georgia';
  color: ${(props) => props.theme.colors.primary};
  margin: 0px;
`

const Buttons = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 14px;
`

const ItemButton = styled('button')`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
  padding: unset;
`

const ItemLink = styled(Link)`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
`

const Label = styled('span')`
  margin-left: 10px;
  ${(props) => props.theme.fonts.label}
`

const NoMeeting = styled('p')`
  padding: 0px 22px;
  margin: 0px;
  ${(props) => props.theme.fonts.subtitle}
`

const Infos = styled('div')`
  margin-top: 9px;
`

const Info = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
`
