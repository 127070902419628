const VERSION = process.env.REACT_APP_VERSION || '0.0.1-DEV'
const ENV: Env = (global as any).ENV || 'dev'

const EnvConfig = (env: Env): EnvConfig => {
  switch (env) {
    case 'dev':
      return {
        SERVER_PREFIX: '',
        WSS_URL: 'wss://loreal-smart-campus.int.cardiweb.com/v2/realtime',
        API_VERSION: '/services/v6',
        API_KEY: 'm28b8GfD4Vp9Ewl4oNJmfHafu',
        azure: {
          clientId: '6f476d53-ef57-4727-b1a4-d73e32f8fe75',
          authority: 'https://login.microsoftonline.com/7761d34f-b968-4d7d-8e99-5618af4c792b',
          serverAPI: 'api://2514c4bd-aa74-4a7c-9566-aefaf363c63a/server-access',
          redirectUri: 'http://localhost:3000',
          additionalScopes: ['User.Read', 'Calendars.ReadWrite', 'User.Read.all'],
        },
      }
    case 're7':
      return {
        SERVER_PREFIX: '',
        WSS_URL: 'wss://loreal-smart-campus.int.cardiweb.com/v2/realtime',
        API_VERSION: '/services/v6',
        API_KEY: 'm28b8GfD4Vp9Ewl4oNJmfHafu',
        azure: {
          clientId: '6f476d53-ef57-4727-b1a4-d73e32f8fe75',
          authority: 'https://login.microsoftonline.com/7761d34f-b968-4d7d-8e99-5618af4c792b',
          redirectUri: 'https://loreal-smart-campus.int.cardiweb.com/',
          serverAPI: 'api://2514c4bd-aa74-4a7c-9566-aefaf363c63a/server-access',
          additionalScopes: ['User.Read', 'Calendars.ReadWrite'],
        },
      }
    case 'preprod':
      return {
        SERVER_PREFIX: '',
        WSS_URL: 'wss://preprod.smartcampus.loreal.cardicloud.com/v2/realtime',
        API_VERSION: '/services/v6',
        API_KEY: 'zvsnsw7huKF4S28H6iWWIdDNo9MS7g',
        azure: {
          clientId: 'b5b6c2fb-d20a-4524-99e3-46360eeb6b3b',
          authority: `https://login.microsoftonline.com/e4e1abd9-eac7-4a71-ab52-da5c998aa7ba`,
          redirectUri: 'https://preprod.smartcampus.loreal.cardicloud.com/',
          serverAPI: 'api://2820eaba-98af-4c20-b25c-92d9d0b19931/login',
          additionalScopes: ['User.Read', 'Calendars.ReadWrite'],
        },
      }
    case 'release':
      return {
        SERVER_PREFIX: '',
        WSS_URL: 'wss://prod.smartcampus.loreal.cardicloud.com/v2/realtime',
        API_VERSION: '/services/v6',
        API_KEY: 'ADG6yhm0vWNBBqytBJJpx90MKj3RHA',
        azure: {
          clientId: 'fabedeb6-7e3a-4a0e-8b2d-7736c105542e',
          authority: `https://login.microsoftonline.com/e4e1abd9-eac7-4a71-ab52-da5c998aa7ba`,
          redirectUri: 'https://prod.smartcampus.loreal.cardicloud.com/',
          serverAPI: 'api://8e8b61d7-4f97-47d8-97a4-0ed5b57460a0/login/login',
          additionalScopes: ['User.Read', 'Calendars.ReadWrite'],
        },
      }
  }
}

const config: Config = {
  ENV,
  VERSION,
  ...EnvConfig(ENV),
}

const load = (env: Env) => {
  Object.assign(config, { ENV: env })
  Object.assign(config, { VERSION })
  Object.assign(config, EnvConfig(env))
}

export default config
export { load }
