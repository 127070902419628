import * as React from 'react'
import styled from 'theme/styled-components'
import theme from 'theme/Theme'

import useI18n from 'i18n/useI18n'

import * as SiteStore from 'site/store'
import useReducer from 'store/useReducer'

import useNavigation from 'core/src/layout/useNavigation'

import Tree from 'components/button/Tree'
import TitleHelmet from 'components/titleHelmet/TitleHelmet'
import Main, { HEADER_HEIGHT } from 'components/main/Main'
import AlertPortal from 'components/alert/Alert'

import CateringForm, { FormValues } from './CateringForm'

import api from './api'

import * as CateringStore from './store'

import { breakpoints } from 'utils/breakpoints'
import Logger from 'utils/Logger'

import { FormikConfig } from 'formik'
import { format } from 'date-fns'

const CateringFormScreen = () => {
  const i18n = useI18n()
  const navigation = useNavigation()

  const currentSite = useReducer(SiteStore.store, (s) => s.site)

  const onSubmit: FormikConfig<Partial<FormValues>>['onSubmit'] = (form, helpers) => {
    if (
      currentSite &&
      form.endingTime &&
      form.startingTime &&
      form.date &&
      form.numberGuests !== undefined &&
      form.instantType &&
      form.cateringPlace &&
      (form.costCenter?.id || form.costCenterId)
    ) {
      api
        .createCateringInstant({
          startingTime: format(form.startingTime, 'HH:mm'),
          endingTime: format(form.endingTime, 'HH:mm'),
          numberGuests: form.numberGuests,
          siteId: currentSite.id.toString(),
          additionalArticles: form.additionalArticles?.map((a) => ({ id: a.id, quantity: a.quantity })),
          comment: form.comment,
          date: format(form.date, 'dd-MM-yyyy'),
          costCenterId: (form.costCenterId || form.costCenter?.id) as string,
          instantTypeId: form.instantType.id,
          referentielReference: form.cateringPlace.referentielReference,
          division: form.division,
        })
        .then((detail) => {
          AlertPortal.open({
            title: i18n.t('screens.meeting.catering.form.submitSuccess'),
            description: i18n.t('screens.meeting.catering.form.submitSuccessDescription'),
            titleIcon: 'check_circle',
            iconColor: theme.colors.available,
          })
          // Mise à jour de la liste des instants commandés
          api.getCateringInstants().then(({ instants }) => CateringStore.actions.setInstants(instants))

          helpers.resetForm()
          helpers.setFieldValue('comment', '')

          // Redirection vers l'écran de détail de l'instant commandé
          navigation.push(`/meeting/catering/${detail.id}`)
        })
        .catch((err) => {
          Logger.error(err)
          AlertPortal.open({
            title: i18n.t('screens.meeting.catering.form.submitError'),
            description: i18n.t('screens.meeting.catering.form.submitErrorDescription'),
          })
        })
    }
  }

  return (
    <Main>
      <ScreenContainer>
        <TitleHelmet title={i18n.t('screens.meeting.catering.title')} />

        <Tree
          previousPages={[{ url: 'meeting', title: 'screens.meeting.title_breadcrumb' }]}
          currentPageTitle={i18n.t('screens.meeting.catering.title')}
        />

        <Title>{i18n.t('screens.meeting.catering.title')}</Title>

        <ContentContainer>
          <CateringForm onSubmit={onSubmit} mode="CREATE" />
        </ContentContainer>
      </ScreenContainer>
    </Main>
  )
}

export default CateringFormScreen

const ScreenContainer = styled.div`
  display: flex;
  min-height: calc(100vh - ${HEADER_HEIGHT + 80}px); // 80px de padding vertical
  padding: 40px 76px;
  background-color: ${(props) => props.theme.colors.background};

  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 40px 35px;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  gap: 60px;
  width: 100%;
  padding: 0 100px;
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 0 50px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 0 0px;
  }
`

const Title = styled.h1`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 28 0 44px;
`
