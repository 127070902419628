import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

export interface ButtonProps {
  style?: ButtonStyle
  label: string
  onClick?: () => void
  ariaLabel?: string
  color?: string
  borderTextColor?: string
  disabled?: boolean
  isLoading?: boolean
  width?: number
  type?: 'reset' | 'button' | 'submit'
}

const MIN_BUTTON_WIDTH = 200

const Button = ({
  style = 'primary',
  label,
  onClick,
  ariaLabel,
  color,
  borderTextColor,
  disabled,
  isLoading = false,
  width,
  type,
}: ButtonProps) => {
  const [Theme] = useTheme()

  const colors = {
    backgroundColor: disabled
      ? Theme.colors.lightGrey
      : color || (style === 'primary' ? Theme.colors.primaryDark : Theme.colors.background),
    borderColor: disabled
      ? Theme.colors.lightGrey
      : color ||
        borderTextColor ||
        (style === 'small'
          ? Theme.colors.buttonDisabled
          : style === 'danger'
          ? Theme.colors.delete
          : Theme.colors.primaryDark),
  }

  return (
    <Container
      type={type || 'button'}
      onClick={!disabled && !!onClick ? onClick : undefined}
      tabIndex={0}
      aria-label={ariaLabel}
      disabled={disabled}
      buttonStyle={style}
      isLoading={isLoading}
      width={width}
      {...colors}>
      <Label disabled={disabled} buttonStyle={style} color={borderTextColor}>
        {label}
      </Label>
    </Container>
  )
}

export default Button

const Container = styled('button')<{
  buttonStyle: ButtonStyle
  backgroundColor: string
  borderColor?: string
  disabled?: boolean
  width?: number
  isLoading: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: ${(props) => (props.buttonStyle === 'small' ? 8 : 10)}px 16px;
  cursor: ${(props) => (props.isLoading ? 'wait' : props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};
  margin: 0;
  ${(props) => (props.width ? `width: ${props.width}px` : `min-width: ${MIN_BUTTON_WIDTH}px`)};

  :hover {
    ${(props) =>
      !props.disabled &&
      (props.buttonStyle === 'small' ? `border-color: ${props.theme.colors.primaryDark}` : 'opacity: 0.8')};
  }
`

const Label = styled('p')<{ disabled?: boolean; buttonStyle: ButtonStyle; color?: string }>`
  ${(props) => (props.buttonStyle === 'small' ? props.theme.fonts.subtitleBold : props.theme.fonts.bodyBold)};
  color: ${(props) =>
    props.disabled
      ? props.theme.colors.buttonDisabled
      : props.color ||
        (props.buttonStyle === 'primary'
          ? props.theme.colors.white
          : props.buttonStyle === 'danger'
          ? props.theme.colors.delete
          : props.theme.colors.primaryDark)};

  text-align: center;
  margin: 0px;
`
