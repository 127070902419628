import { css } from 'styled-components'

const colors: Colors = {
  primary: '#3D3D3D',
  secondary: '#C2013A',
  accent: '#FF7A70',
  primaryDark: '#000000',
  primaryText: '#000000',
  darkText: '#2D2A26',
  secondaryLighter: '#d9b6c0',
  secondaryText: '#FF7A70',
  background: '#ffffff',
  contentBackground: '#F8F8F8',
  disable: '#CCCCCC',
  buttonDisabled: '#9D9CA9',
  urgent: '#FF3030',
  error: '#FF3030',
  delete: '#E30613',

  darkGrey: '#353A3D',
  iconicGrey: '#565655',
  middleGrey: '#9D9CA9',
  lightGrey: '#EBEBEB',
  secondaryLightGrey: '#F8F8F8',
  white: '#FFFFFF',

  open: '#28BB7D',
  close: '#F02A4E',
  near: '#E9BC1D',
  homeFeature: '#E3A535',

  // occupancy
  occupied: '#3D3D3D',
  free: '#00D88D',
  freeOccupied: '#DF9E80',
  booked: '#FF003B',
  noInfo: '#9D9CA9',

  // map
  mapItinerary: '#FF7A70',
  mapDrawer: '#FFFFFF',

  // itinerary
  mobility: '#FF7A70',
  co2: '#009C65',
  blablacar: '#006EF9',
  blablacarAccent: '#E9F0FA',
  unavailable: '#9D9CA9',
  electric: '#F7C733',

  // register
  full: '#AA2800',
  available: '#009C65',
  transparentGreen: 'rgba(0, 156, 101, 0.10)',
  transparentGrey: 'rgba(0, 0, 0, 0.05)',
  transparentRed: 'rgba(194, 1, 58, 0.10)',
  transparentYellow: 'rgba(227, 165, 53, 0.15)',
  coral: '#FF7A70',
  raspberry: '#C2013A',
  gold: '#F8D581',
  silver: '#F2F2F2',
  copper: '#FBA469',

  // restaurant
  menu: 'rgba(0, 150, 64, 0.05)',

  // quiz
  valid: '#009640',
  validLight: '#e9f8f2',
  // error: '#F02A4E',
  errorLight: '#FAE6EA',

  // misc
  backgroundModal: '#0007',
  blueCarouselArrowOnFocus: '#005faa',

  // Catering
  mauve: '#B487B8',
  warningBackground: 'rgba(240, 145, 0, 0.15)',
  warning: '#F09100',

  // Home Header
  transparentWhite: 'rgba(255, 255, 255, 0.8)',
  transparentMiddleGrey: 'rgba(155, 155, 155, 0.25)',

  // functionalities
  functionalities: {
    actu: '#006EF9',
    cgu: '#2C506D',
    home: '#2C506D',
    map: '#36B7C1',
    meeting: '#B487B8',
    move: '#F09100',
    news: '#006EF9',
    phonebook: '#4D4084',
    practicalinformation: '#B6115A',
    register: '#AA2800',
    restaurant: '#009C65',
    safeAtWork: '#E3A644',
    survey: '#FF7A70',
    transport: '#F09100',
  },
  lightFunctionalities: {
    actu: '#DCE7F5',
    home: '#DFE9F2',
    map: '#E1EEEF',
    meeting: '#E4DFEE',
    move: '#F4EBDC',
    news: '#DCE7F5',
    phonebook: '#E1DCEE',
    practicalinformation: '#EEDEE5',
    register: '#EDDFDA',
    restaurant: '#DCECE6',
    safeAtWork: '#F5EBDC',
    survey: '#F6E8E7',
    transport: '#F4EBDC',
  },
}

const theme: Theme = {
  colors: {
    ...colors,
  },
  fonts: {
    h1Bold: {
      fontFamily: 'Loreal Essentielle Bold',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '30px',
      lineHeight: '41px',
      letterSpacing: '0.5px',
      color: colors.primaryText,
    },
    h1: {
      fontFamily: 'Loreal Essentielle',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '30px',
      lineHeight: '41px',
      letterSpacing: '0.5px',
      color: colors.primaryText,
    },
    h2Bold: {
      fontFamily: 'Loreal Essentielle Bold',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: '27px',
      letterSpacing: '0.3px',
      color: colors.primaryText,
    },
    h2: {
      fontFamily: 'Loreal Essentielle',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '24px',
      lineHeight: '27px',
      color: colors.primaryText,
    },
    h3Bold: {
      fontFamily: 'Loreal Essentielle Bold',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '22px',
      color: colors.primaryText,
    },
    h3: {
      fontFamily: 'Loreal Essentielle',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '18px',
      lineHeight: '22px',
      color: colors.primaryText,
    },
    bodyBold: {
      fontFamily: 'Loreal Essentielle Bold',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '19px',
      color: colors.primaryText,
    },
    body: {
      fontFamily: 'Loreal Essentielle',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '19px',
      color: colors.primaryText,
    },
    subtitleBold: {
      fontFamily: 'Loreal Essentielle Bold',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '19px',
      color: colors.primaryText,
    },
    subtitle: {
      fontFamily: 'Loreal Essentielle',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '19px',
      color: colors.primaryText,
    },
    tagTiroir: {
      fontFamily: 'Georgia Bold',
      fontSize: '14px',
      lineHeight: '16px',
      color: colors.primaryText,
    },
    labelBold: {
      fontFamily: 'Loreal Essentielle Bold',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.1px',
      color: colors.primaryText,
    },
    label: {
      fontFamily: 'Loreal Essentielle',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      color: colors.primaryText,
    },
    link: {
      fontFamily: 'Loreal Essentielle',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '15px',
      color: colors.primaryText,
      textDecorationLine: 'underline',
    },
    content: {
      fontFamily: 'Loreal Essentielle',
      fontSize: '20px',
      letterSpacing: '0.2px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '22px',
      color: colors.primaryText,
    },
  },

  constants: {
    menuButtonSize: 40,
  },
}

export const overwriteHtmlFonts = css`
  span,
  div,
  h1,
  h2,
  h3,
  h4,
  p {
    font-family: ${(props) => props.theme.fonts.body.fontFamily} !important;
  }

  b,
  b > span,
  b > div,
  b > h1,
  b > h2,
  b > h3,
  b > h4,
  b > p {
    font-family: ${(props) => props.theme.fonts.bodyBold.fontFamily} !important;
  }
`

export const maxTextLines = (max: number, breakAll = false) => `
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: ${max};
  line-clamp: ${max};
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: ${breakAll ? 'break-all' : 'break-word'};`

export default theme
