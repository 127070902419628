import * as React from 'react'
import styled from 'theme/styled-components'
import { overwriteHtmlFonts } from 'theme/Theme'

import Switch from 'components/button/Switch'

interface Props {
  id: string
  active: boolean
  label?: string
  htmlLabel?: string
  onClick: () => void
  isSeparator?: boolean
  renderContent?: () => JSX.Element
}

const ToggleField = ({ id, active, label, htmlLabel, onClick, isSeparator, renderContent }: Props) => (
  <React.Fragment key={id}>
    {isSeparator && <Separator />}
    <ToggleContainer>
      {!!label ? (
        <ToggleLabel>{label}</ToggleLabel>
      ) : (
        !!htmlLabel && <ToggleLabel dangerouslySetInnerHTML={{ __html: htmlLabel }} />
      )}
      <Switch size="big" active={active} onClick={onClick} />
    </ToggleContainer>
    {!!renderContent && renderContent()}
  </React.Fragment>
)

export default ToggleField

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const ToggleLabel = styled.label`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0;
  ${overwriteHtmlFonts};
`

const Separator = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.secondaryLightGrey};
  margin: 0;
`
