import { OFFICE_MAP } from 'site/utils'

import Alert from 'components/alert/Alert'

import api from './api'
import * as BookingStore from './store'

import { differenceInMinutes, roundToNearestMinutes, addMinutes, isBefore, isWeekend, addDays } from 'date-fns'

import { I18n } from 'i18n/i18n'

const userIsResident = (officeLocation: string, codeSite: string) => {
  const office = OFFICE_MAP[codeSite]
  return !!office && officeLocation.includes(office)
}

const roundToUpperQuarter = () => {
  const roundTime = roundToNearestMinutes(new Date(), { nearestTo: 15 })
  if (isBefore(roundTime, new Date())) {
    // on arrondi au quart d'heure suivant et non au précédent
    const diff = differenceInMinutes(new Date(), roundTime)
    const minutesToAdd = 15 - diff
    const newStart = addMinutes(new Date(), minutesToAdd)
    return newStart
  } else {
    return roundTime
  }
}

/*
TODO MBA
const orderMeetingsByDate = (meetings: MeetingGraph[]) =>
  meetings
    .reduce((acc, cur) => {
      const foundIndex = acc.findIndex((d) => isSameDay(new Date(d.date), new Date(cur.start.dateTime)))
      if (foundIndex > -1) {
        acc[foundIndex].meetings.push(cur)
      } else {
        acc.push({ date: cur.start.dateTime, meetings: [cur] })
      }
      return acc
    }, [] as MeetingGraphByDate[])
    .sort((a, b) => {
      a.meetings.sort((c, d) => (isBefore(new Date(c.start.dateTime), new Date(d.start.dateTime)) ? -1 : 1))
      b.meetings.sort((c, d) => (isBefore(new Date(c.start.dateTime), new Date(d.start.dateTime)) ? -1 : 1))
      return isBefore(new Date(a.date), new Date(b.date)) ? -1 : 1
    })

 { userIsResident, roundToUpperQuarter, orderMeetingsByDate }
*/

const notWeekend = (d: Date) => {
  while (isWeekend(d)) {
    d = addDays(d, 1)
  }
  return d
}

const extractEquipments = (rooms: Referentiel[], lang: string): string[] => {
  const equipments: string[] = rooms.reduce((acc, cur) => {
    if (cur.bookable && !!cur.data && cur.data.salleReunion) {
      if (lang === 'fr' && !!cur.data.equipements_fr) {
        const splitEquipmentsFr = cur.data.equipements_fr.split(' - ')
        splitEquipmentsFr.forEach((e) => {
          acc.push(e)
        })
      } else if (lang === 'en' && !!cur.data.equipements_en) {
        const splitEquipmentsEn = cur.data.equipements_en.split(' - ')
        splitEquipmentsEn.forEach((e) => {
          acc.push(e)
        })
      }
    }
    return acc
  }, [] as string[])
  return Array.from(new Set(equipments)).sort((a, b) => a.localeCompare(b))
}

const extractBuildings = (rooms: Referentiel[]): string[] => {
  const buildings = rooms.reduce((acc, cur) => {
    if (!!cur.building && !!cur.bookable && !!cur.referencialType && cur.referencialType === 'Salle de réunion') {
      acc.push(cur.building)
    }
    return acc
  }, [] as string[])
  return Array.from(new Set(buildings)).sort((a, b) =>
    !isNaN(parseInt(a)) && !isNaN(parseInt(b)) ? parseInt(a) - parseInt(b) : a.localeCompare(b)
  )
}

const findMaxCapacity = (rooms: Referentiel[]): number => {
  if (!rooms || rooms.length === 0) {
    // Sans cette condition, la fonction retourne "-Infinity"
    return 0
  }
  return Math.max(...rooms.map((room) => room.capacity || 0))
}

const displayEmail = (poi: PlanningSearchResult | ReferentielData, lang: string) =>
  (!!poi.data && poi.data[`libelle_${lang}`]) || poi.email

// Fonction de réservation de salle déclarée ici, car on veut pouvoir l'utiliser
// depuis la vue liste des salles et dans le détail d'une salle
const bookRoom = (
  i18n: I18n,
  room: PlanningSearchResult | ReferentielData,
  start: Date,
  end: Date,
  analyticsEvent: () => void,
  setLoading: (loading: boolean) => void
) => {
  const name = displayEmail(room, i18n.lang)
  Alert.open({
    title: i18n.t('screens.meeting.booking.alert.book'),
    description: i18n.t('screens.meeting.booking.alert.bookMessage', { room: name, start: start, end: end }),
    buttons: [
      {
        label: i18n.t('common.yes'),
        onClick: () => {
          Alert.close()
          setLoading(true)

          analyticsEvent()
          api
            .createReservation({
              start: start.toISOString(),
              end: end.toISOString(),
              address: room.email!,
              label: '',
            })
            .then(() =>
              Alert.open({
                title: i18n.t('screens.meeting.booking.alert.success'),
                description: i18n.t('screens.meeting.booking.alert.successMessage', { room: name, date: start }),
              })
            )
            .catch(() =>
              Alert.open({
                title: i18n.t('common.error'),
                description: i18n.t('screens.meeting.booking.alert.errorBookingMessage'),
              })
            )
            .finally(() => {
              // get meetings after booking
              api.getReservations().then((res) => BookingStore.actions.setReservations(res.schedules || []))
              setLoading(false)
            })
        },
      },
      { label: i18n.t('common.no'), onClick: Alert.close },
    ],
  })
}

export {
  userIsResident,
  roundToUpperQuarter,
  notWeekend,
  extractBuildings,
  extractEquipments,
  findMaxCapacity,
  displayEmail,
  bookRoom,
}
