import React from 'react'
import styled from 'theme'
import { maxTextLines, overwriteHtmlFonts } from 'theme/Theme'

import ImageItem from '../image/ImageItem'

import { breakpoints } from 'utils/breakpoints'

import { Link } from 'react-router-dom'

export const MIN_WIDTH = 200
export const MAX_WIDTH = 600
// Largeur à partir de laquelle on force l'affichage sur 3 colonnes
// Sinon on risque d'avoir une grille de plus de 3 colonnes à cause de "auto-fit"
export const WIDTH_TRESHOLD = 1270

interface Props {
  imageUrl?: string
  title: string
  description?: string
  link: string
  ariaLabel?: string
  tag?: string
  dateLabel?: string
  locationLabel?: string
  themeColor: string
  onClick?: () => void
  externalLink?: boolean
  isEvent?: boolean
}

const ListItemRework = ({
  description,
  imageUrl,
  link,
  title,
  ariaLabel,
  tag,
  dateLabel,
  locationLabel,
  themeColor,
  onClick,
  externalLink,
  isEvent,
}: Props) => {
  if (externalLink) {
    return (
      <ExternalItemLink href={link} aria-label={ariaLabel} onClick={onClick} target="_blank" rel="noreferrer">
        <ImageItem
          image={imageUrl || require('core/src/assets/news_default.jpeg').default}
          minWidth={MIN_WIDTH}
          maxWidth={MAX_WIDTH}
        />
        {tag && <ItemTag themeColor={themeColor}>{tag}</ItemTag>}
        <ItemTitle>{title}</ItemTitle>
        {dateLabel && locationLabel && (
          <ItemDate themeColor={themeColor}>
            {dateLabel}
            {locationLabel}
          </ItemDate>
        )}
        {!!description && (
          <Resume>
            <p dangerouslySetInnerHTML={{ __html: description }}></p>
          </Resume>
        )}
      </ExternalItemLink>
    )
  }

  return (
    <ItemLink to={link} aria-label={ariaLabel} onClick={onClick}>
      <ImageItem
        image={imageUrl || require('core/src/assets/news_default.jpeg').default}
        minWidth={MIN_WIDTH}
        maxWidth={MAX_WIDTH}
      />
      {tag && <ItemTag themeColor={themeColor}>{tag}</ItemTag>}
      <ItemTitle>{title}</ItemTitle>
      {((dateLabel && locationLabel) || isEvent) && (
        <ItemDate themeColor={themeColor}>
          {dateLabel}
          {locationLabel}
        </ItemDate>
      )}
      {!!description && (
        <Resume>
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
        </Resume>
      )}
    </ItemLink>
  )
}

export default ListItemRework

const ItemLink = styled(Link)`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-decoration: none;
  min-width: ${MIN_WIDTH}px;
  max-width: ${MAX_WIDTH}px;
  transition: all 0.1s ease-in-out;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: 100%;
  }

  :hover {
    transform: scale(1.02);
  }
`

const ExternalItemLink = styled('a')`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-decoration: none;
  min-width: ${MIN_WIDTH}px;
  max-width: ${MAX_WIDTH}px;
  transition: all 0.1s ease-in-out;

  @media only screen and (max-width: ${breakpoints.phone}px) {
    width: 100%;
  }

  :hover {
    transform: scale(1.02);
  }
`

const ItemTag = styled('p')<{ themeColor: string }>`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.themeColor};
  padding-bottom: 8px;
  word-break: break-word;
`

const ItemTitle = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  max-height: 42px;
  margin: 0 0 8px;
  ${maxTextLines(2)}
`

const ItemDate = styled('p')<{ themeColor: string }>`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.themeColor};
  margin-bottom: 16px;
  max-height: 38px;
  ${maxTextLines(2)}
`

const Resume = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  ${maxTextLines(3)}
  ${overwriteHtmlFonts}
`
