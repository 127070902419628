import * as React from 'react'
import styled from 'theme/styled-components'

import NewsItem from './NewsItem'
import Loader from 'components/status/Loader'
import ButtonLink from 'components/button/ButtonLink'
import { MIN_WIDTH, WIDTH_TRESHOLD } from 'components/list/ListItemRework'

import { breakpoints } from 'utils/breakpoints'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as NewsStore from './store'

import api from './api'

interface Props {
  siteId: number
}

const NewsHomeContent = ({ siteId }: Props) => {
  const i18n = useI18n()

  const news = useReducer(NewsStore.store, (s) => s.news)

  const last = React.useMemo(() => news.sort((a, b) => b.publicationDate.localeCompare(a.publicationDate)), [news])

  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    setLoading(true)

    api
      .all(siteId, 6)
      .then((l) => NewsStore.actions.setNews(l.articles))
      .catch(NewsStore.actions.resetNews)
      .finally(() => setLoading(false))
  }, [siteId])

  if (loading) {
    return <Loader />
  }

  const renderItem = (article: ArticleV4) => (
    <li key={article.id}>
      <NewsItem article={article} />
    </li>
  )

  return (
    <MainContainer>
      <Header>
        <Title>{i18n.t('screens.home.news')}</Title>
      </Header>

      {last.length === 0 ? (
        <Error>{i18n.t('screens.news.empty')}</Error>
      ) : (
        <StyledList>{last.map(renderItem)}</StyledList>
      )}

      <ButtonContainer>
        <ButtonLink
          label={i18n.t('screens.news.more')}
          path="/news"
          ariaLabel={i18n.t('accessibility.ariaLabels.home.seeMoreNews')}
        />
      </ButtonContainer>
    </MainContainer>
  )
}

export default NewsHomeContent

const MainContainer = styled('div')``

const Header = styled('div')`
  flex-direction: row;
  margin-bottom: 30px;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0px;
`

const StyledList = styled('ul')`
  display: grid;
  list-style: none;
  gap: 40px;
  padding: 0;
  margin: 0;
  grid-template-columns: repeat(auto-fit, minmax(${MIN_WIDTH}px, 1fr));

  @media only screen and (min-width: ${WIDTH_TRESHOLD}px) {
    grid-template-columns: repeat(3, minmax(${MIN_WIDTH}px, 1fr));
  }

  @media only screen and (max-width: ${breakpoints.small}px) {
    display: flex;
    flex-direction: column;
    gap: 22px;
  }
`

const ButtonContainer = styled('div')`
  margin: 40px 0px;
  align-self: center;
`

const Error = styled('p')`
  ${(props) => props.theme.fonts.body};
  text-align: center;
  margin: 50px;
`
