import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import { maxTextLines } from 'theme/Theme'

import Icon from 'components/icons/Icon'
import IconLabel from 'components/label/IconLabel'

import { Link } from 'react-router-dom'
import useI18n from 'i18n/useI18n'

interface Props {
  item: SurveyWS
}

const ListItem = ({ item }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  const type = item.category || 'SURVEY'

  return (
    <ItemLink key={item.id} to={`survey/${item.id}`}>
      <ItemInfos>
        <TagContainer>
          <IconLabel
            label={i18n.t(`screens.survey.tags.${type}`)}
            fontSize={15}
            margin={8}
            icon={type === 'SURVEY' ? 'question_round' : 'quiz'}
            iconColor={Theme.colors.primaryDark}
          />
        </TagContainer>

        <ItemTitle>{item.title}</ItemTitle>

        <IconLabel
          label={i18n.t('screens.survey.endDate', { date: new Date(item.endDate) })}
          fontSize={15}
          margin={8}
          icon="agenda_filled"
          iconColor={Theme.colors.iconicGrey}
        />
      </ItemInfos>

      <Icon name="chevron_right" size={20} color={Theme.colors.iconicGrey} />
    </ItemLink>
  )
}

export default ListItem

const ItemLink = styled(Link)`
  display: flex;
  margin-bottom: 28px;
  padding: 20px;
  flex-direction: row;

  border-radius: 15px;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.15);
  overflow: hidden;

  align-items: center;
  justify-content: space-between;
  text-decoration: none;
`

const ItemInfos = styled('div')`
  flex-direction: column;
`

const TagContainer = styled('div')`
  padding: 8px 12px;
  border-radius: 4px;
  align-self: flex-start;
  background-color: ${(props) => props.theme.colors.contentBackground};
`

const ItemTitle = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 20px 0px 16px;
  ${maxTextLines(3)}
`
