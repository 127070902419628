import * as React from 'react'
import styled from 'theme/styled-components'

import Button from 'components/button/Button'
import WarningLabel from 'components/label/WarningLabel'
import DaySelectorList from 'components/planning/DaySelectorList'

import useI18n from 'i18n/useI18n'

import { isAfter } from 'date-fns'
import { CONTENT_WIDTH, filterValidSlots, groupeSlotsByDay } from './utils'
import { dateStringToZonedTime } from 'utils/dateUtils'

interface Props {
  detail: AnimationDetail
  alreadyBooked: Booking[]
  bookingId?: string
  mode?: 'BOOK' | 'MODIFY'
  isEligible: boolean
  couldBookNewSlot: boolean
  onSlotSelect: (slot: Slot) => void
  slotId?: string
}

const SlotsList = ({
  detail,
  alreadyBooked,
  bookingId,
  mode = 'BOOK',
  isEligible,
  couldBookNewSlot,
  onSlotSelect,
  slotId,
}: Props) => {
  const i18n = useI18n()

  const [selectedDay, setSelectedDay] = React.useState<string>()

  // Créneaux dont la date d'inscription est valide
  const validSlots = React.useMemo(() => filterValidSlots(detail?.slots), [detail])

  // Créneaux regroupés par date
  const groupedSlots = React.useMemo(() => groupeSlotsByDay(validSlots), [validSlots])

  // Tous les créneaux du jour sélectionné (si jours multiples)
  const slotsOfSelectedDay = React.useMemo(() => {
    if (validSlots) {
      const filteredSlots = !!selectedDay
        ? validSlots.filter((slot) => slot.startDate.includes(selectedDay))
        : validSlots
      return filteredSlots.sort((a, b) => a.startDate.localeCompare(b.startDate))
    }
    return []
  }, [validSlots, selectedDay])

  // Jours de réservation disponibles
  const slotsDays = React.useMemo(() => (!!groupedSlots ? Object.keys(groupedSlots) : []), [groupedSlots])
  const multipleDays = React.useMemo(() => slotsDays.length > 1 && couldBookNewSlot, [slotsDays, couldBookNewSlot])

  // Liste des jours des autres réservations
  const alreadyBookedDates = React.useMemo(
    () =>
      alreadyBooked
        // Si on est en train de modifier une réservation, on ne garde que les dates des autres réservations
        .filter((booking) => mode !== 'MODIFY' || booking.id !== bookingId)
        .map((booking) => booking.startDate.split('T')[0])
        .filter(Boolean),
    [alreadyBooked]
  )

  const isFull = React.useMemo(() => detail?.isFull || validSlots.length === 0, [detail, validSlots])

  const canBook = React.useMemo(() => !!detail && !isFull && isEligible, [!!detail, isFull, isEligible])

  const canBookMultipleSlotsMultipleDays = React.useMemo(
    () => !!detail.multipleSlotByUser && !!selectedDay && !alreadyBookedDates.includes(selectedDay),
    [detail.multipleSlotByUser, alreadyBookedDates, selectedDay]
  )

  const canBookMultipleSlotsSameDay = React.useMemo(
    () => !!detail.multipleSlotByDay && !!selectedDay && alreadyBookedDates.includes(selectedDay),
    [detail.multipleSlotByDay, alreadyBookedDates, selectedDay]
  )

  const canBookMultipleSlots = React.useMemo(
    () =>
      // L'animation accepte plusieurs réservations par utilisateur sur des jours différents
      canBookMultipleSlotsMultipleDays ||
      // L'utilisateur peut réserver plusieurs créneaux le même jour
      canBookMultipleSlotsSameDay ||
      (mode === 'MODIFY' && alreadyBookedDates.length === 0),
    [canBookMultipleSlotsMultipleDays, canBookMultipleSlotsSameDay, mode, alreadyBookedDates]
  )

  React.useEffect(() => {
    if (!!slotsDays && slotsDays.length > 0) {
      setSelectedDay(slotsDays[0])
    }
  }, [slotsDays])

  const renderSlot = (item: Slot) => {
    const numberUser = item.numberUser || 0
    const limitUser = item.limitUser || 0
    const isAvailable = numberUser < limitUser
    const isAfterMaxDate = isAfter(new Date(), new Date(item.endDateInscription))
    // On doit pouvoir resélectionner le même créneau de la réservation à modifier
    const isSameSlot = item.id === slotId
    const isAlreadyBooked = alreadyBooked.some((booked) => booked.slotId === item.id) || isSameSlot
    const isBookable = (isAvailable && !isAlreadyBooked && !isAfterMaxDate) || isSameSlot
    const canModifySameSlot = mode === 'MODIFY' && isSameSlot

    return (
      <SlotContainer
        key={item.id}
        isAlreadyBooked={isAlreadyBooked}
        isBookable={isBookable && (canBook || canModifySameSlot)}
        onClick={() => {
          if (isBookable && (canBook || canModifySameSlot)) {
            onSlotSelect(item)
          }
        }}>
        <SlotInfo>
          <SlotTime>
            {i18n.t('screens.home.featureDetail.timeSlot', {
              start: dateStringToZonedTime(item.startDate),
              end: dateStringToZonedTime(item.endDate),
            })}
          </SlotTime>

          <SlotOccupancy>
            <SlotOccupancyColored isAvailable={isAvailable && !isAfterMaxDate}>
              {!isAvailable
                ? i18n.t('screens.register.detail.full')
                : isAfterMaxDate
                ? i18n.t('screens.register.detail.dateMaxReached')
                : i18n.t('screens.register.detail.available', { count: limitUser - numberUser })}
            </SlotOccupancyColored>
            {` • ${item.place || detail.place}`}
          </SlotOccupancy>

          {isBookable && (
            <SlotOccupancy>
              {i18n.t('screens.register.detail.deadline', {
                date: dateStringToZonedTime(item.endDateInscription),
              })}
            </SlotOccupancy>
          )}
        </SlotInfo>

        <ButtonContainer>
          <Button
            label={i18n.t(isSameSlot ? 'common.next' : 'screens.register.detail.book')}
            onClick={() => null}
            width={90}
          />
        </ButtonContainer>
      </SlotContainer>
    )
  }

  return (
    <>
      {(canBook || mode == 'MODIFY') && (
        <MainContainer>
          {multipleDays && (
            <DaySelectorList
              days={slotsDays}
              selectedDay={selectedDay}
              setSelectedDay={setSelectedDay}
              maxWidth={CONTENT_WIDTH}
            />
          )}
          {alreadyBooked.length === 0 || canBookMultipleSlots ? (
            <List>{slotsOfSelectedDay.map(renderSlot)}</List>
          ) : (
            <WarningContainer>
              <WarningLabel
                description={
                  !!detail.multipleSlotByUser && !canBookMultipleSlotsMultipleDays
                    ? i18n.t('screens.register.detail.restricted.alreadyBookUserOnlyOneADay')
                    : !!detail.multipleSlotByDay && !canBookMultipleSlotsSameDay
                    ? i18n.t('screens.register.detail.restricted.alreadyBookUserRestrictDay')
                    : i18n.t('screens.register.detail.restricted.alreadyBookUserSimple')
                }
              />
            </WarningContainer>
          )}
        </MainContainer>
      )}
    </>
  )
}

export default SlotsList

const MainContainer = styled('div')`
  position: relative;
`

const Text = styled('p')`
  margin: 0;
  padding: 0;
`

// SLOTS LIST

const List = styled('ul')`
  list-style: none;
  margin: 0;
  padding: 0;
`

const ButtonContainer = styled('div')`
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
`

const SlotContainer = styled('div')<{ isAlreadyBooked?: boolean; isBookable?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${(props) =>
    props.isAlreadyBooked ? props.theme.colors.contentBackground : props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
  border-radius: 12px;
  padding: 20px 16px;
  box-shadow: 0px 2px 3px 0px rgba(146, 146, 146, 0.25);
  margin-top: 16px;
  transition: all 0.25s ease-in-out;
  ${(props) =>
    props.isBookable &&
    `cursor: pointer;
    :hover {
      box-shadow: 0px 5px 6px 4px rgba(161, 161, 161, 0.25);
    }
    :hover ${ButtonContainer} {
      opacity: 1;
    }`};
`

const SlotInfo = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const SlotTime = styled(Text)`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.primaryText};
`

const SlotOccupancy = styled(Text)<{ isAvailable?: boolean }>`
  ${(props) => props.theme.fonts.subtitle};
  text-decoration: none;
  margin-top: 4px;
`

const SlotOccupancyColored = styled('span')<{ isAvailable?: boolean }>`
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => (props.isAvailable ? props.theme.colors.available : props.theme.colors.full)};
  text-decoration: none;
`

// Restricted animation

const WarningContainer = styled.div`
  padding: 16px 0;
  max-width: 700px;
`
