import * as React from 'react'
import styled from 'theme/styled-components'

interface Props {
  children: JSX.Element | JSX.Element[]
  title?: string
  subtitle?: string
  gap?: number
}

const FormSection = ({ children, title, subtitle, gap }: Props) => (
  <SectionContainer id={title} gap={gap}>
    <Row>
      {!!title && <SectionTitle>{title}</SectionTitle>}
      {!!subtitle && <SectionSubtitle>{subtitle}</SectionSubtitle>}
    </Row>
    {children}
  </SectionContainer>
)

export default FormSection

const SectionContainer = styled.div<{ gap?: number }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || 28}px;
  padding: 20px;
  border-radius: 12px;
  width: calc(100% - 40px);
  border: 1px solid ${(props) => props.theme.colors.lightGrey};
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
`

const SectionTitle = styled.h3`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0;
`

const SectionSubtitle = styled.p`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.iconicGrey};
  margin: 2px 6px 0;
`
