import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

interface Props {
  contactMail?: string
}

const RegisterContactMail = ({ contactMail }: Props) => {
  const i18n = useI18n()

  if (!contactMail) {
    return null
  }

  const emailsList = contactMail.split(';')

  return (
    <ContactContainer>
      <ContactLabel>
        {i18n.t('screens.register.detail.restricted.contactMail', { count: emailsList.length })}
        <ContactLabelBold>{emailsList.join(', ')}</ContactLabelBold>
      </ContactLabel>
    </ContactContainer>
  )
}

export default RegisterContactMail

const ContactContainer = styled('div')`
  display: flex;
  gap: 4px;
  margin-top: 28px;
`

const ContactLabel = styled('p')<{ bold?: boolean }>`
  ${(props) => (props.bold ? props.theme.fonts.bodyBold : props.theme.fonts.body)};
  color: ${(props) => props.theme.colors.iconicGrey};
  display: inline;
`

const ContactLabelBold = styled('b')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.iconicGrey};
  display: inline;
`
