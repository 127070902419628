import { I18n } from 'i18n/i18n'
import { MapFloor } from './types'

const DESK_TYPE = 'Bureau flex'
const GROUND = 'RDC'

const getFloorFromFloorId = (floorId: string) => {
  const numsList = floorId.match(/^\d+|\d+\b|\d+(?=\w)/g)

  if (!!numsList && numsList?.length > 0) {
    const numString = numsList.pop()
    if (!!numString) {
      return floorId.includes('LL') ? `SS${numString}` : numString === '0' ? GROUND : numString // Visioglobe ne nous renvoie que du français ou de l'anglais
    }
    return GROUND
  }
  return GROUND
}

const displayName = (poi: Referentiel, lang: string) =>
  (!!poi.data && poi.data[`libelle_${lang}`]) || poi.room || poi.reference

const sortPOIByName = (a: Referentiel, b: Referentiel, i18n: I18n) => {
  const aName = displayName(a, i18n.lang)
  const bName = displayName(b, i18n.lang)

  return aName.localeCompare(bName)
}

const sortFloorById = (a: MapFloor, b: MapFloor) => {
  const aLevel = a.id.replace(new RegExp('LL', 'g'), '-').match(/(-{0,1}[0-9]+)$/)
  const bLevel = b.id.replace(new RegExp('LL', 'g'), '-').match(/(-{0,1}[0-9]+)$/)

  if (aLevel && aLevel.length > 0 && bLevel && bLevel.length > 0) {
    return Number.parseInt(bLevel[0], 10) - Number.parseInt(aLevel[0], 10)
  }

  return a.id > b.id ? -1 : 1
}

const parseReference = (ref: Referentiel) =>
  (ref.referencialType == DESK_TYPE && ref.reference.match(/(.*)[a-zA-Z]$/)?.[1]) || ref.reference

export { DESK_TYPE, getFloorFromFloorId, displayName, sortPOIByName, sortFloorById, parseReference }
