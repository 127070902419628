import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

type Size = 'medium' | 'big'

interface Props {
  onClick: () => void
  active: boolean
  label?: string
  color?: string
  textColor?: string
  disabled?: boolean
  size?: Size
}

const Switch = ({ label, active, onClick, color, textColor, disabled, size }: Props) => {
  const [Theme] = useTheme()

  return (
    <Container onClick={!disabled ? onClick : undefined} disabled={disabled}>
      <SwitchContainer active={active} color={color || Theme.colors.primaryDark} disabled={disabled} size={size}>
        <SwitchTarget active={active} size={size} />
      </SwitchContainer>
      {!!label && <Label color={textColor || Theme.colors.primaryText}>{label}</Label>}
    </Container>
  )
}

export default Switch

const Container = styled('div')<{ disabled?: boolean }>`
  flex-direction: row;
  align-items: center;
  ${(props) => (props.disabled ? 'cursor: wait;' : 'cursor: pointer;')}
`

const SwitchContainer = styled('div')<{ color: string; active: boolean; disabled?: boolean; size?: Size }>`
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.disable : props.active ? props.color : props.theme.colors.middleGrey};
  transition: background-color 0.3s ease-in-out;
  width: ${(props) => (props.size === 'big' ? 60 : 40)}px;
  height: ${(props) => (props.size === 'big' ? 30 : 20)}px;
  border-radius: 20px;
  position: relative;
`

const SwitchTarget = styled('div')<{ active: boolean; size?: Size }>`
  width: ${(props) => (props.size === 'big' ? 22 : 14)}px;
  height: ${(props) => (props.size === 'big' ? 22 : 14)}px;
  border-radius: ${(props) => (props.size === 'big' ? 22 : 14)}px;
  margin: ${(props) => (props.size === 'big' ? 4 : 3)}px;
  background-color: ${(props) => props.theme.colors.background};

  position: absolute;
  top: 0px;
  left: ${(props) => (props.active ? (props.size === 'big' ? 30 : 20) : 0)}px;
  transition: left 0.3s ease-in-out;
`

const Label = styled('p')<{ color: string; disabled?: boolean }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.color};
  text-align: center;
  margin: 0px 0px 0px 10px;
`
