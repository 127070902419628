import * as React from 'react'
import styled from 'theme/styled-components'

import Button from 'components/button/Button'
import DatePickerModal from 'components/picker/DatePickerModal'
import TimePickerModal from 'components/picker/TimePickerModal'
import Modal from 'components/modal/Modal'

import RoomFilter from './RoomFilter'

import useI18n from 'i18n/useI18n'
import useReducer from 'store/useReducer'
import * as SiteStore from 'site/store'
import * as ReferentielStore from 'store/referentiel/referentiel'

import { useFormik, FormikHelpers } from 'formik'
import { breakpoints } from 'utils/breakpoints'
import Alert from 'components/alert/Alert'

import {
  addMinutes,
  differenceInMinutes,
  setHours,
  setMinutes,
  isAfter,
  startOfDay,
  endOfDay,
  min,
  max,
  subMinutes,
  isWeekend,
  addHours,
} from 'date-fns'
import { I18n } from 'i18n/i18n'
import { notWeekend, extractBuildings, extractEquipments, findMaxCapacity, roundToUpperQuarter } from './utils'

interface Values {
  start: Date
  end: Date
  /* Commenté car remplacé par la recherche de salle, à décommenter pour revenir à la recherche de créneau
    range: boolean
    period: 'morning' | 'afternoon' | 'allDay'
  */
  capacity: number
  equipment: string[]
  building: string[]
}

interface Props {
  initialValues: Values
  onFormSubmit: (values: Values) => Promise<any>
  isEditing?: boolean
}

/* Commenté car remplacé par la recherche de salle, à décommenter pour revenir à la recherche de créneau
  const PERIODS = [
    { label: 'morning', start: 9, end: 13 },
    { label: 'afternoon', start: 13, end: 18 },
    { label: 'allDay', start: 9, end: 18 },
  ]
*/

interface PropsFields {
  values: Values
  setFieldValue: (field: string, value: any) => void
  isEditing?: boolean
}

const errorWeekDays = (i18n: I18n) => {
  Alert.open({
    title: i18n.t('screens.meeting.booking.picker.error.weekday.title'),
    description: i18n.t('screens.meeting.booking.picker.error.weekday.content'),
    buttons: [
      {
        label: i18n.t('common.ok'),
        onClick: Alert.close,
      },
    ],
  })
}

const EMPTY_FIELD = '-'

export const SearchFormFields = ({ values, setFieldValue, isEditing }: PropsFields) => {
  const i18n = useI18n()
  const site = useReducer(SiteStore.store, (s) => s.site!)

  const daysOfSearchOffice = React.useMemo(
    () => site.functionalities.find((f) => f.activated && f.type === 'BOOKING')?.param?.daysOfSearchOffice || 1,
    [site]
  )

  const referentiels = useReducer(ReferentielStore.referentielStore, (s) => s.referentiels)

  const MATERIALS = React.useMemo(() => extractEquipments(referentiels, i18n.lang), [referentiels])
  const BUILDINGS = React.useMemo(() => extractBuildings(referentiels), [referentiels])
  const MAXCAPACITY = React.useMemo(() => findMaxCapacity(referentiels), [referentiels])

  const inputCapacity = React.useRef('')

  /* Commenté car remplacé par la recherche de salle, à décommenter pour revenir à la recherche de créneau
    React.useEffect(() => {
      // restore search when range mode change
      if (values.range && search) {
        const start = new Date(search.start)
        const end = new Date(search.end)

        setFieldValue('start', setMinutes(setHours(values.start, start.getHours()), start.getMinutes()))
        setFieldValue('end', setMinutes(setHours(values.end, end.getHours()), end.getMinutes()))
      } else if (!values.range) {
        const per = PERIODS.find((p) => p.label === values.period)

        if (per) {
          setFieldValue('start', setHours(values.start, per.start))
          setFieldValue('end', setHours(values.start, per.end))
        }
      }
    }, [values.range])

    React.useEffect(() => {
      // save search when dates change
      if (values.range) {
        BookingStore.actions.setSearch({ start: values.start.toISOString(), end: values.end.toISOString() })
      }
    }, [values.start, values.end])
  */

  const duration = React.useMemo(() => differenceInMinutes(values.end, values.start), [values])

  // const buildings = ['Building 1', 'Building 2', 'Building 3', 'Building 4', 'Building 5', 'Building 6', 'Building 7', 'Building 8', 'Building 9', 'Building 10', 'Building 11', 'Building 12', 'Building 13', 'Building 14', 'Building 15', 'Building 16', 'Building 17', 'Building 18', 'Building 19', 'Building 20']

  React.useEffect(() => {
    setFieldValue('building', BUILDINGS)
    const newStart = roundToUpperQuarter()
    setFieldValue('start', newStart)
    setFieldValue('end', addHours(newStart, 1))
  }, [setFieldValue])

  /* Commenté car remplacé par la recherche de salle, à décommenter pour revenir à la recherche de créneau
    const openPicker = () =>
      PickerModal.open({
        title: i18n.t('screens.meeting.booking.picker.label.period'),
        data: PERIODS.map((p) => ({ label: i18n.t(`screens.meeting.booking.range.${p.label}`), value: p.label })),
        onItemSelected: (value) => {
          const per = PERIODS.find((p) => p.label === value)

          if (per) {
            setFieldValue('start', setHours(values.start, per.start))
            setFieldValue('end', setHours(values.end, per.end))
            setFieldValue('period', value)
          }
        },
        selected: values.period,
      })
  */

  const openStartDatePicker = () => {
    const ONEDAY = 24 * 60 * 60 * 1000
    let min = new Date()
    let max = new Date(min.getTime() + Math.max(0, daysOfSearchOffice - 1) * ONEDAY)
    min = notWeekend(min)
    max = notWeekend(max)

    DatePickerModal.open({
      date: values.start,
      onDateSelected: (next) => {
        if (isWeekend(next)) {
          errorWeekDays(i18n)
        } else {
          setFieldValue('start', setMinutes(setHours(next, values.start.getHours()), values.start.getMinutes()))
          setFieldValue('end', setMinutes(setHours(next, values.end.getHours()), values.end.getMinutes()))
        }
      },
      min,
      max,
      title: i18n.t('screens.meeting.booking.picker.label.start'),
    })
  }

  const openStartTimePicker = () =>
    TimePickerModal.open({
      time: values.start,
      onTimeSelected: (next: Date) => {
        // if next start is after end, we change end
        if (isAfter(next, values.end)) {
          setFieldValue('end', min([endOfDay(next), addMinutes(next, duration)]))
        }

        setFieldValue('start', next)
      },
      title: i18n.t('screens.meeting.booking.picker.label.startTime'),
    })

  const openEndTimePicker = () =>
    TimePickerModal.open({
      time: values.end,
      onTimeSelected: (next: Date) => {
        // next is a before start, we change start
        if (isAfter(values.start, next)) {
          setFieldValue('start', max([startOfDay(values.end), subMinutes(next, duration)]))
        }

        setFieldValue('end', next)
      },
      title: i18n.t('screens.meeting.booking.picker.label.endTime'),
    })

  const isCapacityValid = (capacity: number) => !isNaN(capacity) && capacity >= 0 && capacity <= MAXCAPACITY

  const openCapacityPicker = () => {
    Modal.open({
      Content: () => (
        <SelectElement>
          <ModalTitle>{i18n.t('screens.meeting.booking.picker.label.capacity')}</ModalTitle>
          <InputValue
            type="text"
            autoFocus
            defaultValue={inputCapacity.current}
            placeholder={i18n.t('screens.meeting.booking.picker.placeholder.capacity', { max: MAXCAPACITY })}
            onChange={(e) => (inputCapacity.current = e.target.value)}
            onBlur={() => {
              const capacity = parseInt(inputCapacity.current, 10)
              isCapacityValid(capacity) && setFieldValue('capacity', capacity)
            }}
          />
          <CapacityButtonContainer>
            <Button
              label={i18n.t('common.validate')}
              onClick={() => {
                const capacity = parseInt(inputCapacity.current, 10)
                if (isCapacityValid(capacity)) {
                  setFieldValue('capacity', capacity)
                  Modal.close()
                } else {
                  Alert.open({
                    title: i18n.t('screens.meeting.booking.picker.error.capacityValue.title'),
                    description: i18n.t('screens.meeting.booking.picker.error.capacityValue.content', {
                      max: MAXCAPACITY,
                    }),
                    buttons: [
                      {
                        label: i18n.t('common.validate'),
                        onClick: Alert.close,
                      },
                    ],
                  })
                }
              }}
            />
          </CapacityButtonContainer>
        </SelectElement>
      ),
      closeButton: true,
    })
  }

  const openEquipmentsPicker = () => {
    Modal.open({
      Content: () => (
        <RoomFilter
          data={MATERIALS}
          filtersSelected={values.equipment}
          onFiltersSelected={(opt) => {
            setFieldValue('equipment', opt)
            Modal.close()
          }}
          typeFilter="equipments"
        />
      ),
      closeButton: true,
    })
  }

  const openBuildingsPicker = () => {
    Modal.open({
      Content: () => (
        <RoomFilter
          data={BUILDINGS}
          filtersSelected={values.building}
          onFiltersSelected={(opt) => {
            setFieldValue('building', opt)
            Modal.close()
          }}
          typeFilter="buildings"
        />
      ),
      closeButton: true,
    })
  }

  return (
    <>
      {/* Commenté car remplacé par la recherche de salle, à décommenter pour revenir à la recherche de créneau
        <PickerButton
          onClick={() => setFieldValue('range', !values.range)}
          aria-label={i18n.t(`accessibility.ariaLabels.booking.customPeriodSwitch${values.range ? 'On' : 'Off'}`)}>
          <PickerLabel>{i18n.t('screens.meeting.booking.picker.label.range')}</PickerLabel>
          <Switch active={values.range} onClick={() => null} />
        </PickerButton>

        {values.range ? (
          <>
            <PickerButton onClick={openStartTimePicker}>
              <PickerLabel>{i18n.t('screens.meeting.booking.picker.label.startTime')}</PickerLabel>
              <PickerValue>
                {i18n.t('screens.meeting.booking.picker.value.startTime', { date: values.start })}
              </PickerValue>
            </PickerButton>

            <PickerButton onClick={openEndTimePicker}>
              <PickerLabel>{i18n.t('screens.meeting.booking.picker.label.endTime')}</PickerLabel>
              <PickerValue>{i18n.t('screens.meeting.booking.picker.value.startTime', { date: values.end })}</PickerValue>
            </PickerButton>
          </>
        ) : (
          <PickerButton onClick={openPicker}>
            <PickerLabel>{i18n.t('screens.meeting.booking.picker.label.period')}</PickerLabel>
            <PickerValue>{i18n.t(`screens.meeting.booking.range.${values.period}`)}</PickerValue>
          </PickerButton>
        )}
      */}

      <PickerButton onClick={openStartDatePicker}>
        <PickerLabel>{i18n.t('screens.meeting.booking.picker.label.start')}</PickerLabel>
        <PickerValue>{i18n.t('screens.meeting.booking.picker.value.start', { date: values.start })}</PickerValue>
      </PickerButton>

      <PickerButton onClick={openStartTimePicker}>
        <PickerLabel>{i18n.t('screens.meeting.booking.picker.label.startTime')}</PickerLabel>
        <PickerValue>{i18n.t('screens.meeting.booking.picker.value.startTime', { date: values.start })}</PickerValue>
      </PickerButton>

      <PickerButton onClick={openEndTimePicker}>
        <PickerLabel>{i18n.t('screens.meeting.booking.picker.label.endTime')}</PickerLabel>
        <PickerValue>{i18n.t('screens.meeting.booking.picker.value.startTime', { date: values.end })}</PickerValue>
      </PickerButton>

      {!isEditing && (
        <>
          <PickerButton onClick={openCapacityPicker}>
            <PickerLabel>{i18n.t('screens.meeting.booking.picker.label.capacity')}</PickerLabel>
            <PickerValue>{values.capacity === 0 ? EMPTY_FIELD : values.capacity}</PickerValue>
          </PickerButton>

          <PickerButton onClick={openEquipmentsPicker}>
            <PickerLabel>{i18n.t('screens.meeting.booking.picker.label.equipments')}</PickerLabel>
            <PickerValue>
              {values.equipment.length === 0
                ? EMPTY_FIELD
                : i18n.t('screens.meeting.booking.picker.label.equipementsCount', { count: values.equipment.length })}
            </PickerValue>
          </PickerButton>

          <PickerButton onClick={openBuildingsPicker}>
            <PickerLabel>{i18n.t('screens.meeting.booking.picker.label.buildings')}</PickerLabel>
            <PickerValue>
              {i18n.t('screens.meeting.booking.picker.label.buildingsCount', { count: values.building.length })}
            </PickerValue>
          </PickerButton>
        </>
      )}
    </>
  )
}

export const SearchForm = ({ initialValues, onFormSubmit, isEditing }: Props) => {
  const i18n = useI18n()
  const site = useReducer(SiteStore.store, (s) => s.site!)

  const onSubmit = (vals: Values, { setSubmitting }: FormikHelpers<Values>) => {
    setSubmitting(true)
    onFormSubmit(vals).finally(() => setSubmitting(false))
  }

  const { handleSubmit, isSubmitting, setFieldValue, values } = useFormik({
    initialValues,
    onSubmit,
  })

  return (
    <MainContainer>
      <Title>
        {i18n.t(`screens.meeting.booking.title${site && site.alternativeFunctionalityDesign ? '_altHeader' : ''}`)}
      </Title>

      <PickerContainer>
        <SearchFormFields values={values} setFieldValue={setFieldValue} isEditing={isEditing} />
      </PickerContainer>

      <ButtonContainer>
        <Button label={i18n.t('screens.meeting.booking.search')} onClick={handleSubmit} disabled={isSubmitting} />
      </ButtonContainer>
    </MainContainer>
  )
}

export default SearchForm

const MainContainer = styled('div')<{ background?: boolean }>`
  background-color: ${(props) => props.theme.colors.background};
  padding: 50px 165px;

  @media only screen and (max-width: ${breakpoints.big}px) {
    padding: 50px 90px;
  }
  @media only screen and (max-width: ${breakpoints.medium}px) {
    padding: 50px 70px;
  }
  @media only screen and (max-width: ${breakpoints.small}px) {
    padding: 50px 35px;
    ${(props) => props.background && `justify-content:center;`}
  }
`

const PickerContainer = styled('div')`
  flex-direction: row;
  flex-wrap: wrap;
  margin: -20px 0px 0px 0px;
  @media only screen and (max-width: ${breakpoints.small}px) {
    flex-direction: column;
    margin: -10px 0px 0px 0px;
  }
`

const PickerButton = styled('button')`
  flex-direction: column;
  display: -webkit-box;
  cursor: pointer;
  margin: 20px 60px 0px 0px;
  @media only screen and (max-width: ${breakpoints.small}px) {
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 0px 0px 0px;
  }
  background-color: ${(props) => props.theme.colors.background};
  border: 0px;
`

const PickerLabel = styled('h2')`
  display: flex;
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.functionalities.meeting};
  margin: 0px 0px 10px;
`

const PickerValue = styled('p')`
  display: flex;
  ${(props) => props.theme.fonts.body};
  @media only screen and (max-width: ${breakpoints.small}px) {
    text-align: right;
  }
  margin: 0px 0px 10px;
`

const ModalTitle = styled('h2')`
  ${(props) => props.theme.fonts.h3Bold};
`

const ButtonContainer = styled('div')`
  @media only screen and (min-width: ${breakpoints.small}px) {
    width: 330px;
  }
  margin-top: 50px;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h2Bold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0px 0px 20px;
  padding: 0;
  flex: 1;
`

const SelectElement = styled('div')`
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  transition: border-color 0.3s, transform 0.3s;
  border: 1px solid transparent;
`

const InputValue = styled('input')`
  ${(props) => props.theme.fonts.body};
  border: 0px;
  width: 100%;
  max-width: 300px;
  background-color: transparent;
  color: ${(props) => props.theme.colors.primaryDark};
  padding: 10px;
  border-radius: 8px;
  margin: 20px auto;
  outline: 1px solid ${(props) => props.theme.colors.functionalities.lightGrey};
`

const CapacityButtonContainer = styled('div')`
  margin: 20px auto 0;
`
