import * as React from 'react'
import styled from 'theme/styled-components'

import useBreakpoint from 'utils/useBreakpoints'

import Back, { HOUR_HEIGHT, MARGIN, OCCUPANCY_HEIGHT } from './Back'
import DaySelectorList from './DaySelectorList'

import { setHours, startOfDay, max as findMax, min as findMin, isBefore, differenceInMinutes } from 'date-fns'

const utils = {
  calculateHourWidth: (totalWidth: number, min: number, max: number) => totalWidth / ((max - min) / 2),
  calculateWidthFromMinutes: (hourWidth: number, start: Date, end: Date) =>
    hourWidth * (differenceInMinutes(end, start) / 60),
  calculatePaddingFromMinutes: (hourWidth: number, minDate: Date, startDate: Date) =>
    hourWidth * (differenceInMinutes(minDate, startDate) / 60),
}

interface Props {
  meetings: RoomReservation[]
  min?: number
  max?: number
  day: string
  setDay: (date: string) => void
  daysList: string[]
}

const REF_DATE = startOfDay(new Date())

const FIRST_POSITION = HOUR_HEIGHT + MARGIN - OCCUPANCY_HEIGHT + 1
const SECOND_POSITION = 2 * (HOUR_HEIGHT + MARGIN)

const Planning = ({ meetings, min = 8, max = 20, day, setDay, daysList }: Props) => {
  const [hourWidth, setHourWidth] = React.useState(0)

  const middle = React.useMemo(() => Math.ceil((max + min) / 2), [min, max])
  const startOfSelectedDay = React.useMemo(() => startOfDay(new Date(day)), [day])
  const middleDate = React.useMemo(() => setHours(startOfSelectedDay, middle), [day, middle])
  const minDate = React.useMemo(() => setHours(startOfSelectedDay, min), [day, min])
  const maxDate = React.useMemo(() => setHours(startOfSelectedDay, max), [day, max])

  const [{ width }, bp] = useBreakpoint()

  const containerRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (!!containerRef.current) {
      setHourWidth(utils.calculateHourWidth(containerRef.current.offsetWidth, min, max))
    }
  }, [containerRef.current, width])

  React.useEffect(() => {
    // Timeout pour recalculer la largeur en cas d'ouverture
    // ou de fermeture automatique du menu latéral
    setTimeout(() => {
      if (!!containerRef.current) {
        setHourWidth(utils.calculateHourWidth(containerRef.current.offsetWidth, min, max))
      }
    }, 200)
  }, [bp])

  const RenderFirstBackComponent = React.useCallback(
    () => <Back start={min} end={middle} width={hourWidth} refDate={REF_DATE} />,
    [max, min, middle, hourWidth]
  )

  const RenderSecondBackComponent = React.useCallback(
    () => <Back start={middle} end={max} width={hourWidth} refDate={REF_DATE} />,
    [max, min, middle, hourWidth]
  )

  const renderMeeting = (m: RoomReservation) => {
    if (!m.start || !m.end) {
      return null
    }

    const start = findMax([new Date(m.start), minDate])
    const end = findMin([new Date(m.end), maxDate])

    const isFirst = isBefore(start, middleDate)
    const isSecond = !isBefore(end, middleDate)

    if (isFirst && isSecond) {
      // case meeting over the middle
      const duration1 = utils.calculateWidthFromMinutes(hourWidth, start, middleDate)
      const duration2 = utils.calculateWidthFromMinutes(hourWidth, middleDate, end)
      const paddingLeft = utils.calculatePaddingFromMinutes(hourWidth, start, minDate)

      return (
        <div key={m.start}>
          <MeetingBook top={FIRST_POSITION} width={duration1} left={paddingLeft} />
          <MeetingBook top={SECOND_POSITION} width={duration2} left={0} />
        </div>
      )
    }

    const duration = utils.calculateWidthFromMinutes(hourWidth, start, end)
    const left = utils.calculatePaddingFromMinutes(hourWidth, start, isFirst ? minDate : middleDate)

    return <MeetingBook key={m.start} top={isFirst ? FIRST_POSITION : SECOND_POSITION} width={duration} left={left} />
  }

  return (
    <MainContainer ref={containerRef}>
      <DaySelectorList days={daysList} selectedDay={day} setSelectedDay={(d) => setDay(d)} />

      <PlanningContainer>
        <RenderFirstBackComponent />
        <RenderSecondBackComponent />
        {meetings.map(renderMeeting)}
      </PlanningContainer>
    </MainContainer>
  )
}

export default Planning

const MainContainer = styled('div')`
  max-width: 600px;
`

const PlanningContainer = styled('div')`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 13px;
`

const MeetingBook = styled('div')<{ top: number; left: number; width: number }>`
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  width: ${(props) => props.width + 1}px;
  height: ${OCCUPANCY_HEIGHT}px;
  background-color: ${(props) => props.theme.colors.booked};
`
