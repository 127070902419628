import * as React from 'react'
import styled from 'theme/styled-components'

interface Props {
  selected: boolean
  label: string
  color: string
}

const PickerItem = ({ selected, label, color }: Props) => (
  <ItemContainer>
    <ItemContent>
      <TypeOptionTitle>{label}</TypeOptionTitle>
      <RadioContainer selected={selected} color={color}>
        {selected && <RadioContent color={color} />}
      </RadioContainer>
    </ItemContent>
    <Separator />
  </ItemContainer>
)

export default PickerItem

const ItemContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const ItemContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  text-align: start;
  padding: 10px;
  border-radius: 8px;
  :hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.contentBackground};
  }
`

const TypeOptionTitle = styled.h5`
  flex: 1;
  ${(props) => props.theme.fonts.subtitleBold};
  color: ${(props) => props.theme.colors.primaryDark};
  margin: 0;
`

const RadioContainer = styled.div<{ selected: boolean; color: string }>`
  height: 18px;
  width: 18px;
  border-radius: 18px;
  border: 1px solid ${(props) => (props.selected ? props.color : props.theme.colors.primaryDark)};
  align-items: center;
  justify-content: center;
`

const RadioContent = styled.div<{ color: string }>`
  height: 12px;
  width: 12px;
  border-radius: 12px;
  background-color: ${(props) => props.color};
`

const Separator = styled('div')`
  height: 1px;
  margin: 0 4px;
  background-color: ${(props) => props.theme.colors.lightGrey};
`
