import * as React from 'react'

import Modal from 'components/modal/Modal'
import Alert from 'components/alert/Alert'

import AnimationDetailContent from './AnimationDetailContent'
import RegisterDrawerContent from './RegisterDrawerContent'

import api from './api'

import { I18n } from 'i18n/i18n'

import { fetchAnimations, fetchReservations } from './utils'
import Logger from 'utils/Logger'

import analytics from 'utils/analytics'
import values from 'firebaseanalytics/firebaseValues.json'

interface Props {
  navigation: Navigation
  i18n: I18n
  detail: AnimationDetail
  slot: Slot
  reservation?: Booking
  familyForm?: FamilyPostForm
  delegatedEmail?: string
  siteId?: number
}

const REMAINING_PLACES = new RegExp(/(?<=\()\d+(?=\sremaining\))/)

/**
 * Gestion de l'ouverture des modals de droits à l'image, puis de confirmation
 * pour réserver une animation et envoyer si besoin le formulaire du Family Day
 * @param navigation
 * @param detail Détail de l'animation
 * @param slot Créneau à réserver (peut être différent de celui de la réservation dans le cas d'une modification)
 * @param reservation Réservation à modifier
 * @param familyForm Valeurs du formulaire (uniquement si l'animation est pour le Family Day)
 * @param emailData Données de l'utilisateur pour lequel on réserve (dans le cas d'une inscription déléguée)
 * @param siteId Id du site pour mettre à jour la liste des animations après la réservation
 */
const openBookingModals = ({
  navigation,
  i18n,
  detail,
  slot,
  reservation,
  familyForm,
  delegatedEmail,
  siteId,
}: Props) => {
  // Droits à l'image, non requis si on incrit un autre collaborateur
  const openImageRightsModal = (imageRights: ImageRights) => {
    const { optionNo, optionYes, text, title } = imageRights
    Alert.open({
      title,
      description: text,
      isDescriptionHtml: true,
      checkBoxList: [
        { id: 'CONSENT', label: optionYes, textAlign: 'left', isLabelHtml: true },
        { id: 'NO_CONSENT', label: optionNo, textAlign: 'left', isLabelHtml: true },
      ],
      buttons: [
        { label: i18n.t('common.cancel'), onClick: Alert.close, style: 'secondary' },
        {
          label: i18n.t('common.continue'),
          onClick: (checkBoxStatus) => {
            if (!!checkBoxStatus && (checkBoxStatus === 'CONSENT' || checkBoxStatus === 'NO_CONSENT')) {
              openConfirmationModal(checkBoxStatus)
            }
          },
          canBeDisabled: true,
        },
      ],
    })
  }

  const openConfirmationModal = (checkBoxStatus?: 'CONSENT' | 'NO_CONSENT') =>
    Alert.open({
      title: i18n.t(`screens.register.detail.${!!reservation ? 'confirmModification' : 'confirmBooking'}`),
      children: <AnimationDetailContent detail={detail} slot={slot} email={delegatedEmail} small />,
      buttons: [
        { label: i18n.t('common.cancel'), onClick: Alert.close, style: 'secondary' },
        {
          label: i18n.t('common.confirm'),
          onClick: () =>
            bookApi(() =>
              !!reservation
                ? api.modify(detail.id, slot.id, reservation.id, checkBoxStatus)
                : api.book(
                    detail.id,
                    slot.id,
                    checkBoxStatus || (!!detail.imageRights && !!delegatedEmail ? 'REGISTERED_BY_ANOTHER' : undefined),
                    delegatedEmail
                  )
            ),
        },
      ],
    })

  const updateFamilyForm = (bookingId: string, oldBookingId?: string) =>
    !!familyForm ? api.updateFamily({ ...familyForm, bookingId, oldBookingId }) : Promise.resolve()

  const bookApi = (action: () => Promise<Booking>) => {
    Alert.close()
    analytics.event({
      event_feature: values.eventName.register,
      event_action: values.actions[!!reservation ? 'editBooking' : 'validateBooking'],
      event_object_id: reservation?.id || detail.id,
    })

    // Réservation du créneau
    action()
      .then((newBooking) =>
        // Envoi du formulaire du Family Day si renseigné
        updateFamilyForm(newBooking.id, reservation?.id)
          .then(() => {
            // Affichage de la pop-up de succès et redirection vers la liste des réservations
            navigation.push('/register?sectionIndex=1')
            setTimeout(
              () =>
                Modal.open({
                  Content: () => (
                    <RegisterDrawerContent
                      type={`${!!reservation ? 'MODIFY_SUCCEED' : 'BOOK_SUCCEED'}`}
                      email={delegatedEmail}
                      booking={newBooking}
                    />
                  ),
                }),
              500
            )
          })
          .catch((err) => {
            Logger.error(err)
            if (err.data.errorType === 'LIMIT_SLOT_REACHED') {
              // Réservation impossible, car la jauge du créneau est dépassée
              const matchingString = err.data.message?.match(REMAINING_PLACES)?.pop()
              const remainingPlaces = matchingString ? parseInt(matchingString) : 0
              Alert.open({
                title: i18n.t('common.error'),
                description: i18n.t(
                  `screens.register.detail.bookResult.${remainingPlaces ? 'remainingPlace' : 'noRemainingPlace'}`,
                  { count: remainingPlaces }
                ),
              })
            } else {
              Alert.open({
                title: i18n.t('screens.register.detail.bookResult.bookFailed'),
                description: i18n.t(`screens.register.detail.bookResult.${!reservation ? 'bookError' : 'modifyError'}`),
              })
            }
          })
      )
      .catch((err) => {
        Logger.error(err)
        Alert.open({
          title: i18n.t('screens.register.detail.bookResult.bookFailed'),
          description:
            !!delegatedEmail && err.code === 400
              ? i18n.t('screens.register.detail.bookResult.alreadyBookedCollaborator', { email: delegatedEmail })
              : i18n.t(`screens.register.detail.bookResult.${!!reservation ? 'modifyError' : 'bookError'}`),
        })
      })
      .finally(() => {
        fetchAnimations(siteId)
        fetchReservations(false)
      })
  }

  if (!!detail.imageRights && !delegatedEmail) {
    return openImageRightsModal(detail.imageRights)
  } else {
    return openConfirmationModal()
  }
}

const RegisterBookingModals = {
  open: openBookingModals,
}

export default RegisterBookingModals
