import * as React from 'react'
import styled from 'theme/styled-components'
import Provider from 'theme/Provider'

import Button from 'components/button/Button'
import ModalLoader from 'components/status/ModalLoader'
import Alert from 'components/alert/Alert'

import { breakpoints } from 'utils/breakpoints'
import useEscKeyPressed from 'utils/useEscKeyPressed'
import { displayName } from 'visioglobe/utils'

import useI18n from 'i18n/useI18n'

import ReactDOM from 'react-dom'
import FocusLock from 'react-focus-lock'
import { useFormik } from 'formik'

import api from 'meeting/booking/api'
import { initialValues, Values } from 'meeting/booking/BookingScreen'
import { SearchFormFields } from 'meeting/booking/SearchForm'

import analytics from 'utils/analytics'
import firebaseValues from 'firebaseanalytics/firebaseValues.json'

interface Props {
  room: Referentiel
}

const BookModal = ({ room }: Props) => {
  const i18n = useI18n()

  useEscKeyPressed(BookPortal.close)

  const onSubmit = (vals: Values) => {
    ModalLoader.open()

    analytics.event({
      event_feature: firebaseValues.eventName.booking,
      event_action: firebaseValues.actions.validateBooking,
      event_object_id: room.reference,
    })

    return api
      .createReservation({
        start: vals.start.toISOString(),
        end: vals.end.toISOString(),
        address: room.email!,
        label: '',
      })
      .then(() => {
        ModalLoader.close()
        Alert.open({
          title: i18n.t('screens.meeting.booking.alert.success'),
          description: i18n.t('screens.meeting.booking.alert.successMessage', {
            room: displayName(room, i18n.lang),
            date: vals.start,
          }),
        })
        BookPortal.close()
      })
      .catch(() => {
        ModalLoader.close()
        Alert.open({
          title: i18n.t('common.error'),
          description: i18n.t('screens.meeting.booking.alert.errorBookingMessage'),
        })
        BookPortal.close()
      })
  }

  const { handleSubmit, isSubmitting, setFieldValue, values } = useFormik({
    initialValues,
    onSubmit,
  })

  return (
    <MainContainer>
      <Back />
      <FocusLock autoFocus={true} returnFocus>
        <Container
          id="BookModalDialog"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modalBookHeading"
          tabIndex={-1}>
          <Title id="modalBookHeading">
            {i18n.t('screens.meeting.booking.modal.titleBookModal', { roomName: room.room })}
          </Title>
          <SearchFormFields values={values} setFieldValue={setFieldValue} />
          <ButtonContainer>
            <Button label={i18n.t('common.book')} style="small" onClick={handleSubmit} disabled={isSubmitting} />
          </ButtonContainer>
          <ButtonContainer>
            <Button label={i18n.t('common.cancel')} style="small" onClick={BookPortal.close} disabled={isSubmitting} />
          </ButtonContainer>
        </Container>
      </FocusLock>
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  opacity: 1;

  -webkit-animation: fadeIn 0.3s linear;
  animation: fadeIn 0.3s linear;
  z-index: 1;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Back = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.colors.backgroundModal};
  border: 0px;
`

const Title = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px 0px 20px;
`

const Container = styled('div')`
  width: 400px;
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.13);
  padding: 40px;
  @media only screen and (max-width: ${breakpoints.small}px) {
    width: 200px;
  }
`
const ButtonContainer = styled('div')`
  margin-top: 10px;
  z-index: -1;
`

let bookRoot: HTMLElement | null

const BookPortal = {
  /*  loadBookings: () => {
    api.getReservations().then((res) => BookingStore.actions.setReservations(res.schedules || []))
  }, */

  open: (props: Props) => {
    bookRoot = document.getElementById('book_root')

    if (bookRoot) {
      bookRoot.style.visibility = 'visible'
      ReactDOM.render(
        <Provider>
          <BookModal {...props} />
        </Provider>,
        bookRoot
      )
    }
  },
  close: () => {
    if (bookRoot) {
      bookRoot.style.visibility = 'hidden'
      ReactDOM.unmountComponentAtNode(bookRoot)
      /* if (updateBookings) {
        BookPortal.loadBookings()
      } */
    }
  },
}

export default BookPortal
