import api from './api'
import * as RegisterStore from './store'

import theme from 'theme/Theme'

import Logger from 'utils/Logger'
import { capitalize } from 'utils/stringUtils'
import { addDays, isAfter, isSameDay, isWithinInterval, startOfDay, subYears } from 'date-fns'

import { I18n } from 'i18n/i18n'

import { UserSelf } from 'store/user/user'
import { DirectoryUser } from 'directory365/types'

import * as Yup from 'yup'

const DEFAULT_WIDTH = 500
const CONTENT_WIDTH = 666

const NAME_REG_EXP = /^[^0-9_!¡?÷?¿\/\\+=@#$%ˆ&*(){}|~<>;:[\]]+$/i
const PHONE_REG_EXP = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\.\/0-9]*$/ // (0|\\+33|0033)[1-9][0-9]{8}
const DATE_REG_EXP = /^[0-9]{2}-[0-9]{2}-[0-9]{4}$/ // format : dd-MM-yyyy

const FAMILY_TOGGLE_TYPES: FamilyToggleType[] = ['delegatedPerson', 'isGroupSupervisor', 'lunchWithChildren']

const MAX_CHILDREN = 5

const formValidationSchema = (
  i18n: I18n,
  divisions: DivisionAnimation[],
  isDelegatedPerson: boolean,
  slot?: Slot,
  detail?: AnimationDetail
) => {
  const requiredLabel = i18n.t('screens.meeting.catering.form.fieldError.required')
  const formatLabel = i18n.t('errors.form.format')
  const dateFormatLabel = i18n.t('errors.form.formatPrecised', {
    format: i18n.t('screens.register.detail.familyForm.placeholders.childBirthDate', {
      startDate: detail?.informationForm?.childStartBirthdateAllowed,
      endDate: detail?.informationForm?.childEndBirthdateAllowed,
    }),
  })

  const nameSchema = Yup.string()
    .matches(NAME_REG_EXP, { excludeEmptyString: true, message: formatLabel })
    .required(requiredLabel)

  const phoneSchema = Yup.string()
    .matches(PHONE_REG_EXP, { excludeEmptyString: true, message: formatLabel })
    .required(requiredLabel)

  const childSchema = Yup.object<Record<keyof ChildBaseForm, Yup.StringSchema>>().shape({
    childLastname: nameSchema,
    childFirstname: nameSchema,
    childBirthDate: Yup.string()
      .matches(DATE_REG_EXP, { excludeEmptyString: true, message: dateFormatLabel })
      .test(
        'birthDate',
        i18n.t('screens.register.detail.familyForm.invalidBirthDate', {
          startDate: detail?.informationForm?.childStartBirthdateAllowed,
          endDate: detail?.informationForm?.childEndBirthdateAllowed,
        }),
        (birthDate) => !birthDate || (!!slot && !!detail && isBirthDateValid(slot, detail, birthDate))
      )
      .required(requiredLabel),
    additionalInformation: Yup.string().nullable().notRequired(),
  })

  const questionSchema = Yup.object<Record<keyof AdditionalQuestionPostForm, Yup.StringSchema | Yup.BooleanSchema>>()
    .shape({
      id: Yup.string(),
      value: Yup.string(),
    })
    .test('value', requiredLabel, ({ value }) => !!value && value.length > 0)
    .required(requiredLabel)

  return Yup.object().shape<Record<keyof FamilyPostForm, Yup.AnySchema>>({
    parentFirstname: nameSchema,
    parentLastname: nameSchema,
    parentPhone: phoneSchema,
    division: Yup.mixed<DivisionAnimation>().when([], {
      is: () => divisions.length > 0,
      then: Yup.string().required(requiredLabel),
      otherwise: Yup.mixed<DivisionAnimation>(),
    }),
    delegatedPerson: Yup.object().when([], {
      is: () => isDelegatedPerson,
      then: Yup.object<Record<keyof DelegatedPerson, Yup.StringSchema>>().shape({
        lastname: nameSchema,
        firstname: nameSchema,
        phone: phoneSchema,
        childLink: Yup.string().required(requiredLabel),
      }),
      otherwise: Yup.object().notRequired(),
    }),
    isGroupSupervisor: Yup.boolean().required(requiredLabel),
    lunchWithChildren: Yup.boolean().required(requiredLabel),
    bookingId: Yup.string().notRequired(),
    oldBookingId: Yup.string().notRequired(),
    children: Yup.array().of(childSchema).min(1).max(MAX_CHILDREN),
    additionalQuestions: Yup.array().of(questionSchema).notRequired(),
  })
}

const getDay = (date?: string) => (!!date ? date.split('T')[0] : '')

const getDateString = (i18n: I18n, start: string, end?: string, full = false) => {
  const startString = capitalize(
    i18n.t(`screens.register.dayMonthYear${full ? 'Full' : 'Short'}`, { date: new Date(start) })
  )
  if (!!end && !isSameDay(new Date(start), new Date(end))) {
    return `${startString} - ${capitalize(
      i18n.t(`screens.register.dayMonthYear${full ? 'Full' : 'Short'}`, { date: new Date(end) })
    )}`
  }
  return startString
}

const groupeSlotsByDay = (slots: Slot[]) =>
  slots
    .sort((a, b) => a.startDate.localeCompare(b.startDate))
    .reduce((acc, cur) => {
      const day = getDay(cur.startDate)
      const daySlots = acc[day] || []
      return { ...acc, [day]: [...daySlots, cur] }
    }, {} as { [key: string]: Slot[] })

const filterAnimations = (animations: AnimationSimple[]) =>
  animations.filter(
    (anim) =>
      !!anim.slotFirstDate && !!anim.slotLastDate && anim.slotFirstDate !== 'unknown' && anim.slotLastDate !== 'unknown'
  )

const filterValidSlots = (slots?: Slot[]) =>
  slots?.filter(isValidSlot).sort((a, b) => a.startDate.localeCompare(b.startDate)) || []

const isValidSlot = (slot: Slot) => isAfter(new Date(slot.startDate), new Date())

const isAnimationFull = (detail: AnimationDetail) => detail.isFull || !detail.slots.some(isValidSlot)

const isMultipleDays = (detail: AnimationSimple | AnimationDetail) =>
  !!detail.slotFirstDate &&
  !!detail.slotLastDate &&
  !isSameDay(new Date(detail.slotFirstDate), new Date(detail.slotLastDate))

const fetchAnimations = (siteId?: number): Promise<ScreenStatus> => {
  if (!siteId) {
    return Promise.reject('error' as ScreenStatus)
  }
  return api
    .getAll(siteId, 'CREATED')
    .then(({ animations }) => {
      RegisterStore.actions.setAnimations(filterAnimations(animations))
      return 'ok' as ScreenStatus
    })
    .catch((err) => {
      Logger.error(err)
      return 'error' as ScreenStatus
    })
}

const fetchReservations = (pastBookings: boolean): Promise<ScreenStatus> =>
  api
    .getReservations(pastBookings)
    .then(({ bookings }) => {
      const sortedBookings = bookings.sort((a, b) => a.startDate.localeCompare(b.startDate))
      pastBookings
        ? RegisterStore.actions.setPastReservations(sortedBookings)
        : RegisterStore.actions.setReservations(sortedBookings)
      return 'ok' as ScreenStatus
    })
    .catch((err) => {
      Logger.error(err)
      return 'error' as ScreenStatus
    })

const getInvitationCardDesign = (design: AnimationCardType) => {
  switch (design) {
    case 'GOLDEN_INVITATION':
      return {
        color: theme.colors.gold,
        image: require('core/src/assets/card_background_gold.png').default,
        shadow:
          '0px 5.846px 30.23px 0px rgba(0, 0, 0, 0.18), 0px 1.462px 1.462px 0px rgba(0, 0, 0, 0.25), 0px 5.846px 13.3px 0px rgba(0, 0, 0, 0.08), 0px 3.755px 14.1px 0px rgba(142, 114, 48, 0.5), 0px 3.755px 37.9px 0px rgba(142, 114, 48, 0.24)',
      }
    case 'SILVER_INVITATION':
      return {
        color: theme.colors.silver,
        image: require('core/src/assets/card_background_silver.png').default,
        shadow:
          '0px 5.846px 30.23px 0px rgba(0, 0, 0, 0.18), 0px 1.462px 1.462px 0px rgba(0, 0, 0, 0.25), 0px 5.846px 13.3px 0px rgba(0, 0, 0, 0.08), 0px 3.755px 14.1px 0px #838383, 0px 3.755px 37.9px 0px #FDFDFD',
      }
    case 'COPPER_INVITATION':
      return {
        color: theme.colors.copper,
        image: require('core/src/assets/card_background_copper.png').default,
        shadow:
          '0px 5.846px 30.23px 0px rgba(0, 0, 0, 0.18), 0px 1.462px 1.462px 0px rgba(0, 0, 0, 0.25), 0px 5.846px 13.3px 0px rgba(0, 0, 0, 0.08), 0px 3.755px 14.1px 0px rgba(142, 114, 48, 0.50), 0px 3.755px 37.9px 0px rgba(142, 114, 48, 0.24)',
      }
  }
}

const findSlot = (slotId: string, slots: Slot[]) => slots.find((slot) => slot.id === slotId)

const getUserName = (user?: UserSelf | DirectoryUser) => {
  if (!!user) {
    const { givenName, surname, displayName } = user
    return !!givenName && !!surname ? `${givenName} ${surname}` : displayName || undefined
  }
}

const isAnimationRestricted = (animation?: AnimationDetail) =>
  !!animation && (animation.restrictedToSite || animation.restrictedToContractType.length > 0)

const getBirthDateFromString = (birthDate: string) => {
  if (birthDate.length === 10) {
    const [day, month, year] = birthDate.split('-')
    return new Date(`${year}-${month}-${day}`)
  }
}

const getMinBirthDate = (slot: Slot) => addDays(subYears(startOfDay(new Date(slot.startDate)), 13), 1)

const getMaxBirthDate = (slot: Slot) => subYears(startOfDay(new Date(slot.startDate)), 5)

/**
 *
 * @param slot créneau de l'animation à réserver
 * @param birthDate date de naissance au format dd-MM-YYYY
 * @returns true si l'enfant a entre 5 ans pile et 13 ans moins 1 jour, false sinon
 */
const isBirthDateValid = (slot: Slot, detail: AnimationDetail, birthDate: string) => {
  const date = getBirthDateFromString(birthDate)
  const interval = {
    start:
      (detail.informationForm && getBirthDateFromString(detail.informationForm.childStartBirthdateAllowed)) ||
      getMinBirthDate(slot),
    end:
      (detail.informationForm && getBirthDateFromString(detail.informationForm.childEndBirthdateAllowed)) ||
      getMaxBirthDate(slot),
  }
  return !!date && isWithinInterval(date, interval)
}

const getChildName = (child: ChildBaseForm) => `${child.childFirstname} ${child.childLastname}`

export {
  DEFAULT_WIDTH,
  CONTENT_WIDTH,
  FAMILY_TOGGLE_TYPES,
  MAX_CHILDREN,
  formValidationSchema,
  getDay,
  getDateString,
  getUserName,
  groupeSlotsByDay,
  filterAnimations,
  filterValidSlots,
  findSlot,
  fetchAnimations,
  fetchReservations,
  getInvitationCardDesign,
  isAnimationFull,
  isAnimationRestricted,
  isMultipleDays,
  isValidSlot,
  isBirthDateValid,
  getChildName,
  getBirthDateFromString,
  getMinBirthDate,
  getMaxBirthDate,
}
