import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import Icon from 'components/icons/Icon'
import Checkbox from 'components/button/Checkbox'

interface Props {
  choices: QuestionChoiceWS[]
  multipleChoices?: boolean
  onChange: (choices: string[]) => void
  editable?: boolean
  isAnswered?: boolean
  maxChoices?: number
  selectedIDs: string[]
}

const ICON_SIZE = 24

const MultipleChoices = ({
  choices,
  multipleChoices,
  onChange,
  editable = true,
  isAnswered = false,
  maxChoices,
  selectedIDs,
}: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const [choicesDisabled, setChoicesDisabled] = React.useState(false)

  React.useEffect(() => {
    if (!!maxChoices) {
      setChoicesDisabled(!!multipleChoices && selectedIDs.length >= maxChoices)
    }
  }, [maxChoices, selectedIDs])

  const onPress = (id: string) => {
    if (editable && !isAnswered) {
      if (selectedIDs.includes(id)) {
        onChange(selectedIDs.filter((sID) => sID !== id))
      } else if (multipleChoices) {
        if (!choicesDisabled) {
          onChange([...selectedIDs, id])
        }
      } else {
        onChange([id])
      }
    }
  }

  const renderItem = (item: QuestionChoiceWS, index: number) => {
    const itemId = item.id
    const isSelected = selectedIDs.includes(itemId)
    const isDisabled = choicesDisabled && !isSelected

    if (!multipleChoices) {
      const answerColor = item.isRightAnswer ? theme.colors.valid : theme.colors.error
      const color = isSelected
        ? isAnswered
          ? answerColor
          : theme.colors.functionalities.survey
        : theme.colors.contentBackground
      return (
        <UniqueContainer>
          <ButtonUniqueChoice
            key={item.id}
            isDisabled={!editable}
            selected={isSelected}
            onClick={() => onPress(itemId)}
            color={isAnswered && !item.isRightAnswer ? theme.colors.contentBackground : color}
            borderColor={
              isSelected ? color : item.isRightAnswer && isAnswered ? theme.colors.primaryDark : theme.colors.middleGrey
            }>
            {isAnswered && isSelected && (
              <Icon
                name={item.isRightAnswer ? 'check' : 'cross'}
                color={isSelected && item.isRightAnswer ? theme.colors.white : answerColor}
              />
            )}
            <LabelUniqueChoice
              selected={isSelected}
              bold={isSelected}
              isAnswered={isAnswered}
              isRightAnswer={item.isRightAnswer}>
              {item.text}
            </LabelUniqueChoice>
          </ButtonUniqueChoice>
          {isAnswered && (item.isRightAnswer || isSelected) && (
            <LabelAnswerResult color={answerColor}>
              {i18n.t(`screens.surveyDetail.quiz.${item.isRightAnswer ? 'right' : 'wrong'}Answer`)}
            </LabelAnswerResult>
          )}
        </UniqueContainer>
      )
    }

    const answerColor = item.isRightAnswer && isSelected ? theme.colors.valid : theme.colors.error

    return (
      <ChoiceContainer
        key={item.id}
        isTopMargin={index > 0}
        selected={isSelected}
        onClick={() => (isDisabled ? undefined : onPress(itemId))}>
        {!isAnswered ? (
          <Checkbox
            active={isSelected}
            onClick={() => onPress(itemId)}
            ariaLabel=""
            color={theme.colors.functionalities.survey}
            borderColor={theme.colors.primaryDark}
          />
        ) : item.isRightAnswer && isSelected ? (
          <Icon name="check" color={theme.colors.valid} size={ICON_SIZE} />
        ) : isSelected || item.isRightAnswer ? (
          <Icon name="cross" color={theme.colors.error} size={ICON_SIZE} />
        ) : (
          <EmptyIcon />
        )}
        <Label isDisabled={isDisabled} isBold={isAnswered && isSelected}>
          {item.text}
        </Label>
        {isAnswered && (item.isRightAnswer || isSelected) && (
          <LabelAnswerResult color={answerColor}>
            {i18n.t(
              `screens.surveyDetail.quiz.${
                item.isRightAnswer && isSelected ? 'right' : item.isRightAnswer ? 'missing' : 'wrong'
              }Answer`
            )}
          </LabelAnswerResult>
        )}
      </ChoiceContainer>
    )
  }

  return <>{choices.map(renderItem)}</>
}

export default MultipleChoices

const Label = styled('p')<{ isDisabled?: boolean; isBold?: boolean }>`
  ${(props) => (props.isBold ? props.theme.fonts.bodyBold : props.theme.fonts.body)};
  color: ${(props) => (props.isDisabled ? props.theme.colors.disable : props.theme.colors.primaryDark)};
  cursor: ${(props) => (props.isDisabled ? 'auto' : 'pointer')};
`

const LabelUniqueChoice = styled('p')<{
  selected: boolean
  bold?: boolean
  isAnswered: boolean
  isRightAnswer?: boolean
}>`
  ${(props) => (props.bold ? props.theme.fonts.bodyBold : props.theme.fonts.body)};
  color: ${(props) =>
    (props.selected && !props.isAnswered) || (props.selected && props.isAnswered && props.isRightAnswer)
      ? props.theme.colors.white
      : props.theme.colors.primaryDark};
  padding-left: ${(props) => (props.isAnswered ? '8px' : '0')};
`

const LabelAnswerResult = styled('p')<{ color: string }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.color};
`

const UniqueContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
  gap: 20px;
`

const ChoiceContainer = styled('li')<{ isTopMargin: boolean; selected: boolean }>`
  align-items: center;
  display: flex;
  flex-direction: row;
  ${(props) => props.isTopMargin && 'margin-top: 20px'};
  gap: 20px;
`

const ButtonUniqueChoice = styled('div')<{
  isDisabled?: boolean
  selected: boolean
  color: string
  borderColor: string
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 20px;
  margin-bottom: 5px;
  max-width: 435px;
  width: 100%;
  background-color: ${(props) => props.color};
  border: 1px solid ${(props) => props.borderColor};
  border-radius: 12px;
  cursor: ${(props) => (props.isDisabled ? 'auto' : 'pointer')};
`

const EmptyIcon = styled('div')`
  width: ${ICON_SIZE}px;
`
